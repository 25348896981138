// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import mainEventBus from '@/utils/evbus';
import axios from 'axios';

import { RootState } from '../index';
import { reloadWalletsData } from '../../modules/casino/store/actions/games';

const DEBUG = false;

export interface SocialReducer {
  avatars: {
    code: string;
    url: string;
    state: number;
  }[];
  nickname: null | {
    default_nickname: string;
    nickname: null | string;
    approved: boolean;
  };
  avatar: null | {
    code: string;
    url: string;
  };
  saving: boolean;
  saved: boolean;
  error: any;
}

type FetchConfigProps = {
  code?: string;
  nickname?: string;
};

type FetchConfigResult = {
  data: any;
  success: boolean;
};

type FetchOpResult = {
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const apiUrl = window.config.playerApiUrl;

export const getAvatars = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'social/getAvatars',
  async (props, { rejectWithValue, getState, dispatch }) => {
    try {
      const state: RootState = getState() as RootState;

      if (state.authentication.access_token && state.authentication.auth_type === 'user') {
        const response = await axios.get(`${apiUrl}/player/data/avatars`, {
          headers: {
            Authorization: 'Bearer ' + state.authentication.access_token,
          },
        });

        if (response.data) {
          return { data: response.data.avatars, success: true };
        }
      }

      return rejectWithValue({
        error: "Couldn't fetch avatars",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export const saveNickname = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'social/saveNickname',
  async (props, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      let response = null;

      if (state.authentication.access_token && state.authentication.auth_type === 'user') {
        response = await axios.post(
          `${apiUrl}/player/data/nickname/save`,
          {
            nickname: props.nickname,
          },
          {
            headers: {
              Authorization: 'Bearer ' + state.authentication.access_token,
            },
          },
        );

        if (response.data?.success) {
          return { data: response.data.data, success: true };
        }
      }

      return rejectWithValue({
        error: response?.data?.error ?? "couldn't save nickname",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export const saveAvatar = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'social/saveAvatar',
  async (props, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      let response = null;

      if (state.authentication.access_token && state.authentication.auth_type === 'user') {
        response = await axios.post(
          `${apiUrl}/player/data/avatar/save`,
          {
            code: props.code,
          },
          {
            headers: {
              Authorization: 'Bearer ' + state.authentication.access_token,
            },
          },
        );

        if (response.data?.success) {
          return { data: response.data.data, success: true };
        }
      }

      return rejectWithValue({
        error: response?.data?.error ?? "couldn't save avatar",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export const getData = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'social/getData',
  async (_props, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      let response = null;

      if (state.authentication.access_token && state.authentication.auth_type === 'user') {
        response = await axios.get(`${apiUrl}/player/data`, {
          headers: {
            Authorization: 'Bearer ' + state.authentication.access_token,
          },
        });

        if (response.data?.success) {
          return { data: response.data, success: true };
        }
      }

      return rejectWithValue({
        error: response?.data?.error ?? "couldn't fetch data",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export const socialSlice = createSlice({
  name: 'social',
  initialState: <SocialReducer>{
    avatars: [],
    nickname: null,
    avatar: null,
    saving: false,
    saved: false,
    error: null,
  },
  reducers: {
    clearState: (state) => {
      state.avatars = [];
      state.nickname = null;
      state.avatar = null;
    },
    clearError: (state) => {
      state.error = null;
      state.saved = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvatars.fulfilled, (state, action) => {
        state.avatars = action.payload.data;

        DEBUG && console.log('getAvatars.fulfilled', action.payload);
      })
      .addCase(getAvatars.pending, (state) => {})
      .addCase(getAvatars.rejected, (state, action) => {
        state.avatars = [];
        DEBUG && console.log('getAvatars.rejected', action.payload);
      })
      .addCase(saveNickname.fulfilled, (state, action) => {
        state.nickname = action.payload.data;
        state.saving = false;
        state.saved = true;

        DEBUG && console.log('saveNickname.fulfilled', action.payload);
      })
      .addCase(saveNickname.pending, (state) => {
        state.saving = true;
        state.saved = false;
        state.error = null;
      })
      .addCase(saveNickname.rejected, (state, action) => {
        state.saving = false;
        state.saved = false;
        state.error = action.payload?.error;
        DEBUG && console.log('saveNickname.rejected', action.payload);
      })
      .addCase(saveAvatar.fulfilled, (state, action) => {
        state.avatar = action.payload.data;
        state.saving = false;
        state.saved = true;

        DEBUG && console.log('saveAvatar.fulfilled', action.payload);
      })
      .addCase(saveAvatar.pending, (state) => {
        state.saving = true;
        state.saved = false;
      })
      .addCase(saveAvatar.rejected, (state, action) => {
        state.saved = false;
        state.saving = false;
        state.error = null;

        DEBUG && console.log('saveAvatar.rejected', action.payload);
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.nickname = action.payload.data.nickname;
        state.avatar = action.payload.data.avatar;

        DEBUG && console.log('getData.fulfilled', action.payload);
      })
      .addCase(getData.pending, (_state) => {})
      .addCase(getData.rejected, (state, action) => {
        state.nickname = null;
        state.avatar = null;
        DEBUG && console.log('getData.rejected', action.payload);
      });
  },
});

export const { clearState, clearError } = socialSlice.actions;

export default socialSlice.reducer;
