import styled from 'styled-components';
import React, { useContext } from 'react';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

type HtmlTag = {
  className: string;
  $styleText: string;
  children?: React.ReactNode; // best, accepts everything React can render
};

const ImageDiv = styled.img<HtmlTag>((props) => props.$styleText);
const ContainerDiv = styled.div<HtmlTag>((props) => props.$styleText);

const cache: any = {};

export const Image = (componentProps: any) => {
  let props = componentProps;

  const [svgContent, setSvgContent] = React.useState({
    data: '<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" />',
    loaded: '',
  });

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  React.useEffect(() => {
    if (props?.properties?.asSvg && props?.properties?.src && svgContent.loaded !== props?.properties?.src) {
      if (typeof cache[props?.properties?.src] === 'string') {
        // we have the svg in cache. use it
        setSvgContent({ data: cache[props?.properties?.src], loaded: props?.properties?.src });
        return;
      } else if (cache[props?.properties?.src] instanceof Promise) {
        // we have a request in progress for this svg. reuse the promise
        cache[props?.properties?.src].then((data: any) => {
          setSvgContent({ data: data, loaded: props?.properties?.src });
        });
        return;
      }

      // fetch the svg
      (async function () {
        try {
          const src = props.properties.src;

          cache[props?.properties?.src] = fetch(src).then(async (data) => {
            if (data.headers.get('Content-Type') === 'image/svg+xml') {
              const svgHtml = await data.text();
              if (svgHtml) {
                cache[props?.properties?.src] = svgHtml;
                setSvgContent({ data: svgHtml, loaded: props?.properties?.src });
              }
              return svgHtml;
            }
          });
        } catch (err) {}
      })();
    }
  }, [props.properties, svgContent]);

  if (!isVisible) return null;

  const src = props.properties.src;

  const extraDataProps: any = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key: any) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      } else if (key === 'aria-label') {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }

  if (props?.properties?.asSvg) {
    return (
      <ContainerDiv
        className={props.className ?? null}
        $styleText={props.styleText}
        style={props.style}
        onClick={props.properties.onClick}
        {...extraDataProps}
        dangerouslySetInnerHTML={{ __html: svgContent.data }}
      />
    );
  }

  return (
    <ImageDiv
      className={props.className ?? null}
      src={src}
      srcSet={props.properties.srcset ?? null}
      sizes={props.properties.sizes ?? null}
      alt={props.properties.alt}
      loading={props.properties.loading ?? 'eager'}
      $styleText={props.styleText}
      style={props.style}
      onClick={props.properties.onClick}
      {...extraDataProps}
    />
  );
};

export default React.memo(Image, equalWithoutChildren);
