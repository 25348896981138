import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, NavLink as NavigateLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { handleActions } from '../../components/action-handler/actions';
import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const LinkContainerComponent = styled.a((props) => props.$styleText);
const NavigateLinkComponent = styled(NavigateLink)((props) => props.$styleText);

export const LinkContainer = (componentProps) => {
  let props = componentProps;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);
  if (!isVisible) return null;

  let { href, target, text, actionType, eventName } = props.properties;
  text = text ? t(text) : '';

  const handleClick = (e) => {
    if (actionType === 'event' && eventName) {
      handleActions(eventName, props, navigate, dataElementContext, e);
      return;
    }

    if (target === '_self' && href.indexOf('/') === 0) {
      navigate(href);
    }
  };

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      } else if (key === 'aria-label' || key === 'preventScrollReset') {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }

  if (actionType === 'react-router' || (actionType === 'event' && eventName === 'show-match-details')) {
    return (
      <NavigateLinkComponent
        to={href}
        className={props.className ?? ''}
        $styleText={props.styleText}
        style={props.style}
        onClick={props.properties.onClick || (actionType === 'event' && eventName === 'show-match-details' ? handleClick : null)}
        {...extraDataProps}
      >
        {componentProps.children}
      </NavigateLinkComponent>
    );
  }

  return (
    <LinkContainerComponent
      className={props.className ?? ''}
      href={href}
      target={target}
      $styleText={props.styleText}
      style={props.style}
      onClick={props.properties.onClick ?? handleClick}
      {...extraDataProps}
    >
      {componentProps.children}
    </LinkContainerComponent>
  );
};

export default React.memo(LinkContainer, equalWithoutChildren);
