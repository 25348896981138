import { liveConstants, digitainConstants } from '../actions/constants';
//import NSoftState from "./state.json";
//import mergeAll from "lodash/fp/mergeAll";
import { isEqual } from 'lodash-es';
import { produce, original } from 'immer';
import { debug, sortArrayByKey, pushUnique } from '../../utils';
import { applyUpdate, isLive } from '../../utils/digitain';
import { getTr, stdMatch } from '../../utils/nsoft';

const DEBUG = false;
const STAKE_DEBUG = false;

const makeInitialState = () => ({
  stateType: 'live',
  fullStateLoaded: false,
  betsCacheKey: 'none',
  sports: {},
  categories: {},
  tournaments: {},
  bets: {},
  marketGroups: {
    0: {},
  },
  data: {},
  odds: {},
  matches: {},
  matchesBySport: {},
  updatesQueue: [],
});

const buildData = draft => {
  debug('building data');

  draft.data = Object.values(draft.matches).reduce((acc, m) => {
    if (!(m.idSport in acc)) {
      acc[m.idSport] = {};
    }

    if (!(m.idCategory in acc[m.idSport])) {
      if (!(m.idCategory in draft.categories)) {
        console.error(`category ${m.idCategory} for match ${m.idMatch} not found in categories`, draft.categories);
      } else {
        draft.categories[m.idCategory].idSport = m.idSport;
      }

      acc[m.idSport][m.idCategory] = {};
    }

    if (!(m.idTournament in acc[m.idSport][m.idCategory])) {
      if (!(m.idTournament in draft.tournaments)) {
        console.error(
          `tournament ${m.idTournament} for match ${m.idMatch} not found in tournaments`,
          draft.tournaments
        );
      } else {
        draft.tournaments[m.idTournament].idCategory = m.idCategory;
        draft.tournaments[m.idTournament].idSport = m.idSport;
      }

      acc[m.idSport][m.idCategory][m.idTournament] = {};
    }

    m.mType = 'live';
    m.brId = m.idMatch;
    m.provider = 'digitain';

    acc[m.idSport][m.idCategory][m.idTournament][m.idMatch] = m;

    // if (m.matchBets) {
    //   m.matchBets.forEach(mb => {
    //     if (mb.mbOutcomes) mb.mbOutcomes.forEach(mbo => (draft.odds[mbo.idMbo] = mbo));
    //   });
    // }

    return acc;
  }, {});

  const sportsToSort = {};

  draft.matchesBySport = Object.values(draft.matches).reduce((acc, m) => {
    if (!(m.idSport in acc)) {
      acc[m.idSport] = [];
    }

    acc[m.idSport].push(m);
    sportsToSort[m.idSport] = true;

    return acc;
  }, {});

  Object.keys(sportsToSort).forEach(k => sortArrayByKey(draft.matchesBySport[k], 'matchDateTime'));

  draft.updatesQueue = [];

  debug('done building data');
};

const parseLiveState = (draft, data) => {
  if (draft === null) {
    draft = makeInitialState();
  }

  if (data.sports !== null) {
    Object.values(data.sports).forEach(s => {
      s.sportName = getTr(s.sportName);
      s.sportShortName = getTr(s.sportShortName);
    });
    draft.sports = data.sports;
  }

  if (data.categories !== null) {
    Object.values(data.categories).forEach(c => {
      c.categoryName = getTr(c.categoryName);
      c.categoryShortName = getTr(c.categoryShortName);
    });
    draft.categories = data.categories;
  }

  if (data.tournaments !== null) {
    Object.values(data.tournaments).forEach(t => {
      t.tournamentName = getTr(t.tournamentName);
      t.tournamentShortName = getTr(t.tournamentShortName);
    });
    draft.tournaments = data.tournaments;
  }

  if (data.bets !== null) {
    Object.values(data.bets).forEach(s => {
      Object.values(s).forEach(b => {
        b.betName = getTr(b.betName);
        b.betShortName = getTr(b.betShortName);
      });
    });
    draft.bets = data.bets;
  }

  if (data.matches !== null) {
    Object.values(data.matches).forEach(m => {
      // m.brId = m.id;
      // m.team1Name = getTr(m.team1Name);
      // m.team1ShortName = getTr(m.team1ShortName);
      // m.team2Name = getTr(m.team2Name);
      // m.team2ShortName = getTr(m.team2ShortName);
      // m.periodShortName = getTr(m.periodShortName);

      // if (m.matchBets && Array.isArray(m.matchBets)) {
      //   m.matchBets.forEach(mb => {
      //     mb.mbDisplayName = getTr(mb.mbDisplayName);

      //     if (mb.mbOutcomes && Array.isArray(mb.mbOutcomes)) {
      //       mb.mbOutcomes.forEach(mbo => {
      //         mbo.mboType = getTr(mbo.mboType);
      //         mbo.mboDisplayName = getTr(mbo.mboDisplayName);
      //         mbo.betName = getTr(mbo.betName);
      //       });
      //     }
      //   });
      // }
      stdMatch(m);
    });
    draft.matches = data.matches;
  }

  // if (data.betGroups) {
  //   draft.marketGroups = Object.values(data.betGroups).reduce((acc, bg) => {
  //     acc[bg.idSport] = acc[bg.idSport] || {};
  //     acc[bg.idSport][bg.idBetGroup] = {
  //       id: bg.idBetGroup,
  //       name: bg.betGroupName,
  //       shortName: bg.betGroupShortName,
  //       description: bg.betGroupDescription,
  //       position: bg.betGroupPosition,
  //       active: bg.BetGroupActive,
  //     };

  //     bg.bets.forEach(betId => {
  //       if (bg.idSport in draft.bets && betId in draft.bets[bg.idSport]) {
  //         draft.bets[bg.idSport][betId].betGroups = draft.bets[bg.idSport][betId].betGroups || [];
  //         draft.bets[bg.idSport][betId].betGroups.push(bg.idBetGroup);
  //       } else {
  //         //console.log(`bet ${betId} referenced by group ${bg.idBetGroup} not found`);
  //       }
  //     });

  //     return acc;
  //   }, {});

  //   draft.marketGroups[0] = draft.marketGroups[0] || {};
  // }

  if (data.marketGroups) {
    const mkg = {};

    for (const [sid, sd] of Object.entries(data.marketGroups)) {
      mkg[sid] = mkg[sid] || {};

      for (const [gid, gd] of Object.entries(sd)) {
        gd.nameEN = gd.name?.[2] ?? gd.name?.[0]; // we need the english name for sorting

        gd.name = getTr(gd.name);
        gd.shortName = getTr(gd.shortName);

        mkg[sid][gid] = gd;
      }
    }

    draft.marketGroups = mkg;
    draft.marketGroups[0] = draft.marketGroups[0] || {};
  }

  buildData(draft);

  return draft;
};

const setLiveMatch = (draft, m) => {
  draft.matches[m.idMatch] = m;

  let hasError = false;

  if (!(m.idSport in draft.data)) {
    draft.data[m.idSport] = {};
  }

  if (!(m.idCategory in draft.data[m.idSport])) {
    if (!(m.idCategory in draft.categories)) {
      console.error(`category ${m.idCategory} for match ${m.idMatch} not found in categories`, draft.categories);
      hasError = true;
    }
    // draft.categories[m.idCategory].idSport = m.idSport;

    draft.data[m.idSport][m.idCategory] = {};
  }

  if (!(m.idTournament in draft.data[m.idSport][m.idCategory])) {
    if (!(m.idTournament in draft.tournaments)) {
      console.error(`tournament ${m.idTournament} for match ${m.idMatch} not found in tournaments`, draft.tournaments);
      hasError = true;
    }
    // draft.tournaments[m.idTournament].idCategory = m.idCategory;
    // draft.tournaments[m.idTournament].idSport = m.idSport;

    draft.data[m.idSport][m.idCategory][m.idTournament] = {};
  }

  m.mType = 'live';
  m.brId = m.idMatch;

  // if (m.matchBets) {
  //   m.matchBets.forEach(mb => {
  //     if (mb.mbOutcomes) mb.mbOutcomes.forEach(mbo => (draft.odds[mbo.idMbo] = mbo));
  //   });
  // }

  draft.data[m.idSport][m.idCategory][m.idTournament][m.idMatch] = m;

  return hasError;
};

const removeMatch = (draft, mid, pid) => {
  // check if we need to remove a period
  if (pid) {
    if (!(pid in draft.matches)) {
      //debug(`parent match ${pid} not found`);
      return;
    }

    let pm = draft.matches[pid];

    if (!(pm && pm.periods && Array.isArray(pm.periods))) {
      return;
    }

    for (let i = 0; i < pm.periods.length; i++) {
      if (pm.periods[i].idMatch === mid) {
        pm.periods.splice(i, 1);
        break;
      }
    }

    return;
  }

  const m = draft.matches[mid];

  if (!m) {
    return;
  }

  const mIdx = draft.matchesBySport[m.idSport].findIndex(am => am.idMatch === m.idMatch);
  if (mIdx !== -1) {
    draft.matchesBySport[m.idSport].splice(mIdx, 1);
  }

  delete draft.matches[m.idMatch];

  if (!(m.idSport in draft.data)) {
    console.error(`sport ${m.idSport} not found in data`);
    return;
  }

  if (!(m.idCategory in draft.data[m.idSport])) {
    console.error(`category ${m.idCategory} not found in data`);
    return;
  }

  if (!(m.idTournament in draft.data[m.idSport][m.idCategory])) {
    console.error(`tournament ${m.idTournament} not found in data`);
    return;
  }

  delete draft.data[m.idSport][m.idCategory][m.idTournament][m.idMatch];

  if (Object.keys(draft.data[m.idSport][m.idCategory][m.idTournament]).length === 0) {
    delete draft.data[m.idSport][m.idCategory][m.idTournament];
  }
  if (Object.keys(draft.data[m.idSport][m.idCategory]).length === 0) {
    delete draft.data[m.idSport][m.idCategory];
  }
  if (Object.keys(draft.data[m.idSport]).length === 0) {
    delete draft.data[m.idSport];
  }
};

const addMatches = (draft, data) => {
  // add sports
  if ('sports' in data) {
    Object.values(data.sports).forEach(s => {
      draft.sports[s.idSport] = s;
    });
  }

  // add categories
  if ('categories' in data) {
    Object.values(data.categories).forEach(c => {
      draft.categories[c.idCategory] = c;
    });
  }

  // add tournaments
  if ('tournaments' in data) {
    Object.values(data.tournaments).forEach(t => {
      t.brId = t.idTournament;
      draft.tournaments[t.idTournament] = t;
    });
  }

  let sportsToSort = [];

  // add matches
  Object.values(data.matches).forEach(m => {
    m.brId = m.idMatch;

    setLiveMatch(draft, m);

    draft.matches[m.idMatch] = m;

    if (!(m.idSport in draft.matchesBySport)) {
      draft.matchesBySport[m.idSport] = [m];
    } else {
      draft.matchesBySport[m.idSport].push(m);
    }

    pushUnique(sportsToSort, m.idSport);
  });

  sportsToSort.forEach(k => sortArrayByKey(draft.matchesBySport[k], 'matchDateTime'));
};

const addMatch = (draft, data) => {
  if (!isLive(data.match)) {
    return;
  }

  let pid = null;
  if (data.match.parentIdMatch) {
    pid = data.match.parentIdMatch;
  }

  // add dependencies if needed
  if (!pid) {
    // add sport
    if (data.sport && !(data.sport.idSport in draft.sports)) {
      data.sport.sportName = getTr(data.sport.sportName);
      data.sport.sportShortName = getTr(data.sport.sportShortName);
      draft.sports[data.sport.idSport] = data.sport;
    }

    // add category
    if (data.category && !(data.category.idCategory in draft.categories)) {
      data.category.categoryName = getTr(data.category.categoryName);
      data.category.categoryShortName = getTr(data.category.categoryShortName);
      draft.categories[data.category.idCategory] = data.category;
    }

    // add tournament
    if (data.tournament && !(data.tournament.idTournament in draft.tournaments)) {
      data.tournament.tournamentName = getTr(data.tournament.tournamentName);
      data.tournament.tournamentShortName = getTr(data.tournament.tournamentShortName);
      draft.tournaments[data.tournament.idTournament] = data.tournament;
    }
  }

  // add match
  const m = data.match;

  // m.team1Name = getTr(m.team1Name);
  // m.team1ShortName = getTr(m.team1ShortName);
  // m.team2Name = getTr(m.team2Name);
  // m.team2ShortName = getTr(m.team2ShortName);
  // m.periodShortName = getTr(m.periodShortName);

  // if (m.matchBets && Array.isArray(m.matchBets)) {
  //   m.matchBets.forEach(mb => {
  //     mb.mbDisplayName = getTr(mb.mbDisplayName);

  //     if (mb.mbOutcomes && Array.isArray(mb.mbOutcomes)) {
  //       mb.mbOutcomes.forEach(mbo => {
  //         mbo.mboType = getTr(mbo.mboType);
  //         mbo.mboDisplayName = getTr(mbo.mboDisplayName);
  //         mbo.betName = getTr(mbo.betName);
  //       });
  //     }
  //   });
  // }
  stdMatch(m);

  if (pid) {
    if (!(pid in draft.matches)) {
      //debug(`parent match ${pid} not found`);
      return;
    }

    let pm = draft.matches[pid];

    let found = false;

    pm.periods = pm.periods || [];

    for (let i = 0; i < pm.periods.length; i++) {
      if (pm.periods[i].idMatch === m.idMatch) {
        pm.periods[i] = m;
        found = true;
        break;
      }
    }

    if (!found) {
      pm.periods.push(m);

      pm.periods.sort((a, b) => {
        if (a.position !== b.position) {
          return a.position - b.position;
        }
        if (a.partTypeId !== b.partTypeId) {
          return a.partTypeId - b.partTypeId;
        }
        if (a.periodId !== b.periodId) {
          return a.periodId - b.periodId;
        }
        if (a.periodName !== b.periodName) {
          return a.periodName.localeCompare(b.periodName);
        }
        return 0;
      });
    }

    return;
  }

  let needSort = false;
  if (!(m.idMatch in draft.matches)) {
    needSort = true;
  }

  m.brId = m.idMatch;

  if (setLiveMatch(draft, m)) {
    console.error(`failed to add match ${m.idMatch}`, data);
  }

  if (window.config.oneMarketState === '1') {
    if (!draft.matches[m.idMatch] || draft.matches[m.idMatch]?._loaded === false) {
      m._loaded = false;
      m.matchBets = m.matchBets && m.matchBets.length ? [m.matchBets[0]] : [];
    }
  }

  draft.matches[m.idMatch] = m;

  if (!(m.idSport in draft.matchesBySport)) {
    draft.matchesBySport[m.idSport] = [m];
  } else {
    const mIdx = draft.matchesBySport[m.idSport].findIndex(am => am.idMatch === m.idMatch);
    if (mIdx !== -1) {
      draft.matchesBySport[m.idSport][mIdx] = m;
    } else {
      draft.matchesBySport[m.idSport].push(m);
    }
  }

  if (needSort) {
    sortArrayByKey(draft.matchesBySport[m.idSport], 'matchDateTime');
  }
};

// const reportUpdate = (...args) => {
//   DEBUG && debug(args);
// };

// const applyUpdate = draft => {
//   debug(`applying ${draft.updatesQueue.length} updates`);

//   for (const u of draft.updatesQueue) {
//     switch (u.type) {
//       case "sport_created": {
//         reportUpdate(`sport ${u.data.idSport}/${u.data.sportName} created`);
//         draft.sports[u.data.idSport] = u.data;

//         break;
//       }
//       case "sport_updated": {
//         reportUpdate(`sport ${u.data.idSport}/${u.data.sportName} updated`);
//         draft.sports[u.data.idSport] = u.data;

//         break;
//       }
//       case "sport_deleted": {
//         // Sport identifier, int (32-bit signed integer) for entity
//         reportUpdate(`sport ${u.data} deleted`);
//         delete draft.sports[u.data];

//         break;
//       }
//       case "category_created": {
//         reportUpdate(`category ${u.data.idCategory}/${u.data.categoryName} created`);
//         draft.categories[u.data.idCategory] = u.data;

//         break;
//       }
//       case "category_updated": {
//         reportUpdate(`category ${u.data.idCategory}/${u.data.categoryName} updated`);
//         draft.categories[u.data.idCategory] = u.data;

//         break;
//       }
//       case "category_deleted": {
//         // Championship identifier, int (32-bit signed integer) for entity
//         reportUpdate(`category ${u.data} deleted`);
//         delete draft.categories[u.data];

//         break;
//       }
//       case "tournament_created": {
//         reportUpdate(`tournament ${u.data.idTournament}/${u.data.tournamentName} created`);
//         draft.tournaments[u.data.idTournament] = u.data;

//         break;
//       }
//       case "tournament_updated": {
//         reportUpdate(`tournament ${u.data.idTournament}/${u.data.tournamentName} updated`);
//         draft.tournaments[u.data.idTournament] = u.data;

//         break;
//       }
//       case "tournament_deleted": {
//         // Tournament identifier, int (32-bit signed integer) for entity
//         reportUpdate(`tournament ${u.data.idTournament}/${u.data.tournamentName} deleted`);
//         delete draft.tournaments[u.data];

//         break;
//       }
//       case "match_created": {
//         // if (u.data.sport) {
//         //   reportUpdate(`sport ${u.data.sport.idSport}/${u.data.sport.sportName} created`);
//         //   draft.sports[u.data.sport.idSport] = u.data.sport;
//         // }
//         // if (u.data.category) {
//         //   reportUpdate(`category ${u.data.category.idCategory}/${u.data.category.categoryName} created`);
//         //   draft.categories[u.data.category.idCategory] = u.data.category;
//         // }
//         // if (u.data.tournament) {
//         //   reportUpdate(`tournament ${u.data.tournament.idTournament}/${u.data.tournament.tournamentName} created`);
//         //   draft.tournaments[u.data.tournament.idTournament] = u.data.tournament;
//         // }
//         // if (u.data.match) {
//         //   reportUpdate(`match ${u.data.match.idMatch} created`);
//         //   draft.matches[u.data.match.idMatch] = u.data.match;
//         // }
//         addMatch(draft, u.data);

//         break;
//       }
//       case "match_updated": {
//         const nm = u.data.match;

//         reportUpdate(`match ${nm.idMatch} updated`);

//         if (!(nm.idMatch in draft.matches)) {
//           debug(`match ${nm.idMatch} not found. Creating...`);

//           addMatch(draft, u.data);
//         } else {
//           const m = { ...draft.matches[nm.idMatch] };

//           for (const k in nm) {
//             if (k !== "matchBets") {
//               m[k] = nm[k];
//               continue;
//             }

//             for (const umb of nm.matchBets) {
//               const idx = m.matchBets.indexOf(mb => mb.idBet === umb.idBet);

//               if (idx === -1) {
//                 m.matchBets.push(umb);
//               } else {
//                 m.matchBets[idx] = umb;
//               }
//             }
//           }

//           draft.matches[nm.idMatch] = m;
//         }

//         break;
//       }
//       case "match_replace": {
//         reportUpdate(`match ${u.data.idMatch} replaced`);
//         draft.matches[u.data.idMatch] = u.data;

//         break;
//       }
//       case "match_deleted": {
//         // MatchDeletedData
//         for (const id of u.data) {
//           reportUpdate(`match ${id} deleted`);
//           removeMatch(draft, id);
//         }

//         break;
//       }
//       case "stake_created": // List Stake of entities
//       case "stake_updated": {
//         // List Stake of entities
//         const valid = [];

//         if (!(u.data && Array.isArray(u.data))) {
//           debug(`invalid stake update`, u.data);
//           break;
//         }

//         for (const s of u.data) {
//           // if (s.idMbo in draft.odds) {
//           //   const so = draft.odds[s.idMbo];

//           //   for (const k in s) {
//           //     if (k === "mboOddValue") {
//           //       if (s.mboOddValue < so.mboOddValue) {
//           //         debug(`odd ${s.idMbo} decreased from ${so.mboOddValue} to ${s.mboOddValue}`);

//           //         so.changeDir = "down";
//           //       } else if (s.mboOddValue > so.mboOddValue) {
//           //         debug(`odd ${s.idMbo} increased from ${so.mboOddValue} to ${s.mboOddValue}`);

//           //         so.changeDir = "up";
//           //       }
//           //     }

//           //     so[k] = s[k];
//           //   }

//           //   valid.push(s);
//           // } else {
//           //   draft.odds[s.idMbo] = s;

//           // {
//           //   "idMatch": "14130099",
//           //   "idBet": "702",
//           //   "idMbo": "84970282",
//           //   "idBo": "702/1",
//           //   "mboActive": true,
//           //   "mboType": "Win1",
//           //   "mboDisplayName": "Win1",
//           //   "mboOddValue": 8.35,
//           //   "mboPosition": 1005
//           // }

//           if (s.idMatch in draft.matches) {
//             const m = draft.matches[s.idMatch];

//             let found = false;

//             const mbs = [...m.matchBets];

//             for (const mb of mbs) {
//               if (mb.idBet !== s.idBet) {
//                 // next if not the searched odd group
//                 continue;
//               }

//               // odd group found, now search for the odd
//               for (let i = 0; i < mb.mbOutcomes.length; i++) {
//                 if (mb.mbOutcomes[i].idMbo !== s.idMbo) {
//                   // next if not the searched odd
//                   continue;
//                 }

//                 const so = mb.mbOutcomes[i];

//                 if (s.mboOddValue < so.mboOddValue) {
//                   DEBUG && debug(`odd ${s.idMbo} decreased from ${so.mboOddValue} to ${s.mboOddValue}`);

//                   s.changeDir = "down";
//                 } else if (s.mboOddValue > so.mboOddValue) {
//                   DEBUG && debug(`odd ${s.idMbo} increased from ${so.mboOddValue} to ${s.mboOddValue}`);

//                   s.changeDir = "up";
//                 }

//                 mb.mbOutcomes[i] = s;

//                 STAKE_DEBUG &&
//                   reportUpdate(`updated odd ${s.idMbo} for event ${s.idMatch}, bet ${s.idBet} on position ${i}`);

//                 found = true;
//               }

//               if (!found) {
//                 // odd not found, insert it
//                 for (let i = 0; i < mb.mbOutcomes.length; i++) {
//                   if (mb.mbOutcomes[i].mboPosition > s.mboPosition) {
//                     mb.mbOutcomes.splice(i, 0, s);

//                     STAKE_DEBUG &&
//                       reportUpdate(`inserted odd ${s.idMbo} for event ${s.idMatch}, bet ${s.idBet} on position ${i}`);

//                     found = true;
//                     break;
//                   }
//                 }
//               }
//             }

//             if (found) {
//               m.matchBets = mbs;
//             }

//             STAKE_DEBUG &&
//               !found &&
//               reportUpdate(`could not insert odd ${s.idMbo} for event ${s.idMatch}, bet ${s.idBet} not found`);
//           } else {
//             STAKE_DEBUG && reportUpdate(`event ${s.idMatch} not found`);
//           }
//         }
//         // }

//         reportUpdate(`${u.type}, total ${u.data.length}, valid ${valid.length}`);

//         break;
//       }
//       case "stake_deleted": {
//         // StakeRemove
//         const valid = [];
//         let errors = 0;

//         for (const sr of u.data) {
//           if (sr.ID in draft.odds) {
//             delete draft.odds[sr.ID];

//             valid.push(sr);
//           } else {
//             STAKE_DEBUG && reportUpdate(`odd ${sr.ID} of event ${sr.EventId} not found`);
//             errors++;
//           }

//           if (sr.EventId in draft.matches) {
//             const m = draft.matches[sr.EventId];

//             let found = false;

//             for (const mb of m.matchBets) {
//               for (let i = 0; i < mb.mbOutcomes.length; i++) {
//                 if (mb.mbOutcomes[i].idMbo === sr.ID) {
//                   found = true;
//                   mb.mbOutcomes.splice(i, 1);
//                   break;
//                 }
//               }

//               if (found) break;
//             }

//             if (!found) {
//               STAKE_DEBUG && reportUpdate(`odd ${sr.ID} not found in event ${sr.EventId}`);
//               errors++;
//             }
//           } else {
//             STAKE_DEBUG && reportUpdate(`failed to delete odd ${sr.ID}, event ${sr.EventId} not found`);
//             errors++;
//           }
//         }

//         reportUpdate(`${u.type}, total ${u.data.length}, valid ${valid.length}, errors ${errors}`);

//         if (valid.length) {
//           u.data = valid;
//         } else {
//           u.data = null;
//         }

//         break;
//       }
//       default: {
//         reportUpdate(`unhandled update type ${u.type}`);
//       }
//     }
//   }

//   draft.updatesQueue = [];
// };

const liveReducer = (state = makeInitialState(), action) =>
  produce(state, draft => {
    switch (action.type) {
      case digitainConstants.SET_MINI_STATE: {
        //console.log("live mini state", action.data);

        if (!draft.fullStateLoaded) {
          draft.sports = action.data.sports;
          draft.categories = action.data.categories;
          draft.tournaments = action.data.tournaments;
          draft.bets = action.data.bets;
          draft.data = action.data.data;
          draft.matches = action.data.matches;
          if (action.data.marketGroups) {
            draft.marketGroups = action.data.marketGroups;
          }

          buildData(draft);
        }

        break;
      }
      case digitainConstants.SET_PARSED_STATE: {
        debug('live state', action.data);
        return action.data;
      }
      case digitainConstants.SET_STATE: {
        debug('live state', action.data);
        /*
        parseLiveState(draft, action.data);
        draft.fullStateLoaded = true;
        break;
        */

        // let's see if we don't use a draft with all the proxy stuff improve things
        const newState = parseLiveState(null, action.data);
        newState.fullStateLoaded = true;
        return newState;
      }
      case digitainConstants.SET_PROGRESSIVE_STATE: {
        debug('live progressive state', action.data);
        if (action.reset) {
          //parseLiveState(draft, action.data);

          // let's see if we don't use a draft with all the proxy stuff improve things
          const newState = parseLiveState(null, action.data);
          if (action.last) {
            newState.fullStateLoaded = true;
          }
          return newState;

        } else {
          const ndraft = parseLiveState(null, action.data);

          draft.sports = { ...draft.sports, ...ndraft.sports };
          draft.categories = { ...draft.categories, ...ndraft.categories };
          draft.tournaments = { ...draft.tournaments, ...ndraft.tournaments };
          draft.bets = { ...draft.bets, ...ndraft.bets };
          draft.matches = { ...draft.matches, ...ndraft.matches };
          draft.data = { ...draft.data, ...ndraft.data };
          draft.matchesBySport = { ...draft.matchesBySport, ...ndraft.matchesBySport };
        }
        if (action.last) {
          draft.fullStateLoaded = true;
        }
        break;
      }
      case digitainConstants.SET_BETS_CACHE_KEY: {
        debug('live bets cache key', action.bkey);
        draft.betsCacheKey = action.bkey;
        break;
      }
      case digitainConstants.QUEUE_UPDATES: {
        // debug("live update", action.data);
        draft.updatesQueue = draft.updatesQueue.concat(action.data);
        break;
      }
      case digitainConstants.MERGE_QUEUE: {
        if (draft.updatesQueue.length) {
          debug('merge updates');
          applyUpdate(draft, addMatch, removeMatch, setLiveMatch);
        }
        break;
      }
      case digitainConstants.CLEAR_QUEUE: {
        debug('clear updates');
        draft.updatesQueue = [];
        break;
      }
      case digitainConstants.LIVE_UPDATES: {
        if (action.data) {
          //debug('live updates');
          applyUpdate(draft, addMatch, removeMatch, setLiveMatch, action.data);
        }
        break;
      }
      case liveConstants.UPDATE_MATCH: {
        const m = action.data;
        m._loaded = true;

        draft.matches[action.data.idMatch] = m;
        setLiveMatch(draft, m);

        if (m.idSport in draft.matchesBySport) {
          const idIdx = draft.matchesBySport[m.idSport].findIndex(mbs => mbs.idMatch === m.idMatch);
          if (idIdx !== -1) {
            draft.matchesBySport[m.idSport].splice(idIdx, 1, m);
          } else {
            draft.matchesBySport[m.idSport].push(m);
          }
        } else {
          draft.matchesBySport[m.idSport] = [m];
        }

        break;
      }
      case liveConstants.REMOVE_TOURNAMENT: {
        let deleteTournament = true;

        Object.keys(draft.data).forEach(idSport => {
          Object.keys(draft.data[idSport]).forEach(idCategory => {
            if (action.data.idTournament in draft.data[idSport][idCategory]) {
              if (Object.keys(draft.data[idSport][idCategory][action.data.idTournament]).length === 0) {
                delete draft.data[idSport][idCategory][action.data.idTournament];
              } else {
                deleteTournament = false;
              }
            }
          });
        });

        if (deleteTournament) {
          delete draft.tournaments[action.data.idTournament];
        }

        break;
      }
      case liveConstants.REMOVE_CATEGORY: {
        let deleteCategory = true;

        Object.keys(draft.data).forEach(idSport => {
          if (action.data.idCategory in draft.data[idSport]) {
            if (Object.keys(draft.data[idSport][action.data.idCategory]).length === 0) {
              delete draft.data[idSport][action.data.idCategory];
            } else {
              deleteCategory = false;
            }
          }
        });

        if (deleteCategory) {
          delete draft.categories[action.data.idCategory];
        }

        break;
      }
      case liveConstants.BET_META: {
        break;
      }
      case liveConstants.SET_MATCH: {
        console.log('set match', action.data);

        if (action.data?.idMatch) {
          const m = action.data;
          if (m.mType === 'live') {
            m._loaded = true;
            draft.matches[action.data.idMatch] = m;
            setLiveMatch(draft, m);

            if (m.idSport in draft.matchesBySport) {
              const idIdx = draft.matchesBySport[m.idSport].findIndex(mbs => mbs.idMatch === m.idMatch);
              if (idIdx !== -1) {
                draft.matchesBySport[m.idSport].splice(idIdx, 1, m);
              } else {
                draft.matchesBySport[m.idSport].push(m);
              }
            } else {
              draft.matchesBySport[m.idSport] = [m];
            }
          }
        }

        break;
      }
      case liveConstants.SET_MATCHES: {
        console.log('set matches', action.data);

        for (const m of action.data) {
          if (m.mType === 'live') {
            m._loaded = true;
            draft.matches[action.data.idMatch] = m;
            setLiveMatch(draft, m);

            if (m.idSport in draft.matchesBySport) {
              const idIdx = draft.matchesBySport[m.idSport].findIndex(mbs => mbs.idMatch === m.idMatch);
              if (idIdx !== -1) {
                draft.matchesBySport[m.idSport].splice(idIdx, 1, m);
              } else {
                draft.matchesBySport[m.idSport].push(m);
              }
            } else {
              draft.matchesBySport[m.idSport] = [m];
            }
          }
        }

        break;
      }
      default:
        break;
    }
  });

export default liveReducer;
