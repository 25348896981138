// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { RootState } from '../index';

const DEBUG = true;

type FetchConfigProps = {
  id: string;
};

type FetchConfigResult = {
  data: {
    data: any[];
  };
  id: string;
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

const apiUrl = window.config.statsApiUrl;

export const fetchLeagueStats = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'leagueStats/list',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const state: RootState = getState() as RootState;

      const apiMapping: any = {
        '2-0-2': 'public/sports/euro-2024/stats', // Performance stats

        '0-0-0': 'public/sports/euro-2024-qr/groups/ranking', // Qualification main ranking
        '0-0-1': 'public/sports/euro-2024-qr/groups/top-scorers', // Qualification top scorer
        '0-2-0': 'public/sports/euro-2024-qr/groups/results', // Qualification main results

        '1-0-1': 'public/sports/euro-2024-qr/promotion/top-scorers', // Qualification Promotion top scorer
        '1-2-0': 'public/sports/euro-2024-qr/promotion/results', // Qualification Promotion results

        '2-0-0': 'public/sports/euro-2024-main/groups/ranking', // Main Groups results
        '2-0-1': 'public/sports/euro-2024-main/groups/top-scorers', // Main Groups top scorer
        '2-1-0': 'public/sports/euro-2024-main/groups/fixtures ', // Main Groups results
        '2-2-0': 'public/sports/euro-2024-main/groups/results', // Main Groups results

        '3-0-1': 'public/sports/euro-2024-main/playoff/top-scorers', // Main Playoff top scorer
        '3-1-0': 'public/sports/euro-2024-main/playoff/fixtures', // Main Playoff results
        '3-2-0': 'public/sports/euro-2024-main/playoff/results', // Main Playoff results
      };

      if (apiMapping[id] === undefined) {
        return rejectWithValue({
          error: 'Invalid id',
        });
      }

      const response = await axios.get(`${apiUrl}/${apiMapping[id]}`, {
        headers: {
          Authorization: 'Bearer ' + state.authentication.access_token,
        },
      });

      if (response.data) {
        return { data: response.data, id: id, success: true };
      }

      return rejectWithValue({
        error: `Couldn't fetch stats data [${id}]`,
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

export interface ConfigReducer {
  data: any;
  loading: boolean;
  requested: any;
  error: any;
}

export const leagueStatsSlice = createSlice({
  name: 'leagueStats',
  initialState: <ConfigReducer>{
    data: {},
    loading: false,
    requested: {},
    error: null,
  },
  reducers: {
    clearLeagueStats: (state) => {
      state.data = {};
      state.loading = false;
      state.requested = {};
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeagueStats.fulfilled, (state, action) => {
        state.loading = false;

        DEBUG && console.log('fetchLeagueStats.fulfilled', action.payload);

        if (action.payload.success) {
          if (action.payload.data) {
            const data: any = action.payload.data;
            if (action.payload.id) {
              state.data[action.payload.id] = data;
            }
          }
        }
      })
      .addCase(fetchLeagueStats.pending, (state, action) => {
        if (action.meta?.arg?.id) state.requested[action.meta.arg.id] = true;
        state.loading = true;
      })
      .addCase(fetchLeagueStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.error;
        DEBUG && console.log('fetchLeagueStats.rejected', action.payload);
      });
  },
});

export const { clearLeagueStats } = leagueStatsSlice.actions;

export default leagueStatsSlice.reducer;
