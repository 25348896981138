//DO NOT REMOVE - This is related to the popup switch
// check if newsite cookie is set
if (!document.cookie.includes('newsite=yes; expires')) {
  // get domain without www and subdomains
  // let domain = window.location.hostname.replace('www.', '');
  // domain = domain.split('.');
  // domain = domain[domain.length - 2] + '.' + domain[domain.length - 1];
  document.cookie = 'newsite=yes; expires=Tue, 01 Jan 2069 12:00:00 UTC;path=/';
}

const removeMeta = () => {
  try {
    document.querySelector('head>title').remove();
  } catch (e) {}

  const metas = [
    'description',
    'og:description',
    'twitter:description',
    'keywords',
    'og:keywords',
    'twitter:keywords',
    'og:site_name',
    'twitter:site',
    'twitter:creator',
    'og:image',
    'twitter:image',
    'og:image:width',
    'og:image:height',
    'og:image:alt',
    'twitter:image:alt',
    'og:type',
    'og:locale',
    'og:locale:alternate',
    'og:video',
    'og:video:width',
    'og:video:height',
    'og:video:type',
    'og:video:alt',
    'twitter:card',
    'thumbnail',
    'og:title',
  ];

  metas.forEach((meta) => {
    try {
      document.querySelector(`meta[property="${meta}"]`).remove();
    } catch (e) {}
  });

  const links = ['canonical', 'og:url', 'twitter:url'];

  links.forEach((meta) => {
    try {
      document.querySelector(`link[rel="${meta}"]`).remove();
    } catch (e) {}
  });
};
removeMeta();
