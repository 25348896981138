import { wrap, proxy } from 'comlink';
import { takeEvery, takeLatest, put, cancelled } from 'redux-saga/effects';
import openSocket from 'socket.io-client';
import axios from 'axios';
import moment from 'moment';
import getStore from '../../store';
import { prematchConstants, betsSlipConstants } from '../actions/constants';
import * as actionTypes from '../../../casino/store/actions/actionTypes';
import {
	prematchCopyState,
	prematchSetState,
	prematchAddMatches,
	prematchSetMatch,
	prematchSetMatches,
	prematchClearExpired,
	prematchMergeMatches,
	prematchSetLoadingState,
	prematchFetchSportByDay,
	prematchMergeState,
	prematchFetchMatches,
} from '../actions/prematch';
import {
	betsSlipPrematchCreateTicketSuccess,
	betsSlipPrematchCreateTicketError,
	betsSlipPrematchCreateTicketReoffer,
	betsSlipPrematchEvalTicketStr,
	betsSlipPrematchEvalTicketData,
	betsSlipLoad,
	betsSlipComputeWin,
	betsSlipPrematchMatchesLoaded,
} from '../actions/betsSlip';
import { appSaveReservedTicket, appSaveErrorLog } from '../actions/app';
import { application as casinoAppConstants } from '../../../casino/store/actions/actionTypes';
import { isArray } from 'lodash-es';
import { debug, uuidv4 } from '../../utils';
import { stdMatchStartTime } from '../../utils/nsoft';
import { createIntervalTicker } from '../../../utils/intervals';
import { getBetsState } from '../selectors/betData';

import { nSoftPrematch, routingKey, nSoftCommon, nSoftTicket } from '../../api/config/nsoft';

import { normalizePreMatchTickets } from '../../utils/normalizeTickets';
import { ticketStoreOpen, ticketWinnerFunStoreOpen, ticketWinnerFunStoreOpenPrepare, ticketSetBetradarId } from '../actions/tickets';



import { requestWallet } from '../../../casino/store/actions/wallet';

import { unstable_trace as trace } from 'scheduler/tracing';

import * as prematch from './digitain/prematch';

import { activeWinnerFunCampaigns } from '../../utils/winnerFunUtils';

import { getLanguage } from '../../../utils/i18n';

// const ticketsUrl = "https://services-staging.7platform.com";
// const ticketCompanyUuid = "28ec1e93-68f0-49fd-9ab5-639d88169625";
//const ticketsUrl = "https://services-ro2.7platform.com";
//const ticketCompanyUuid = "04301c5a-6b6c-4694-aaf5-f81bf665498c";

//const apiURL = "https://staging-sportsbook-sm-distribution-api.nsoft.com";
//const apiURL = "https://sportsbook-sm-distribution-api.nsoft.com";

//const wsURL = "https://staging-sportsbook-sm-distribution-socket.nsoft.com";
//const wsURL = "https://sportsbook-sm-distribution-socket.nsoft.com";

//const cpv = "3657d0f3-f54d-4dc8-9077-578940875c8d";
//const cpv = "6442b1ed-e06b-45df-810c-cff5a89900f8";
//const productName = "SportsbookSM";

//const socketCompanyUuid = "b99752b3-443c-4c80-b559-945a95c4b805";
//const socketCompanyUuid = "04301c5a-6b6c-4694-aaf5-f81bf665498c";

//const productInstanceUuid = "6442b1ed-e06b-45df-810c-cff5a89900f8";
//const productInstanceUuid = "be14879c-e617-47d7-abea-854dd85b5c4a";
//const productInstanceId = 1017294;
//const productInstanceUuid = "8a3f73e2-0a45-42d5-9488-9b6ec164923a";

//const protocol = "sio1";
//const product = "prematch";

const HOURS_OFFSET = window.config.nsoftEnabled === '1' ? 0 : 9; // how many hours after midnight to prefetch

const fetchMetaOld = (cancelToken, now, prematchEndTime, excludeMarkets) => {
	//console.log("fetchMeta " + getLanguage());

	const params = {
		'filter[from]': now,
		'filter[to]': prematchEndTime,
		timezone: 'Europe/Bucharest',
		language: {
			default: getLanguage(),
			category: getLanguage(),
			sport: getLanguage(),
			tournament: getLanguage(),
			market: getLanguage(),
			marketGroup: getLanguage()
		},
		dataFormat: {
			default: 'object',
			sports: 'array',
			categories: 'array',
			tournaments: 'array',
			tournamentGroups: 'array',
			markets: 'array',
			marketGroups: 'array'
		},
		companyUuid: nSoftPrematch.companyUuid,
		deliveryPlatformId: 3,
		dataStructure: 'flat',
		exclude: 'marketOfferTemplates'
	};

	if (excludeMarkets) {
		params['exclude'] = 'markets,marketGroups,marketOfferTemplates';
	}

	return axios.get(nSoftPrematch.apiURL + '/api/v1/meta', {
		params,
		cancelToken
	});
};

const fetchTopOfferOld = (cancelToken, now, prematchEndTime) =>
	axios.get(nSoftPrematch.apiURL + '/api/v1/topOffer', {
		params: {
			'filter[from]': now,
			'filter[to]': prematchEndTime,
			timezone: 'Europe/Bucharest',
			language: {
				default: getLanguage(),
				category: getLanguage(),
				sport: getLanguage(),
				tournament: getLanguage(),
				market: getLanguage(),
				marketGroup: getLanguage()
			},
			companyUuid: nSoftPrematch.companyUuid,
			deliveryPlatformId: 3,
			sort: 'position',
			topOffer: {
				matches: true
			}
		},
		cancelToken
	});

const fetchEventsOld = (cancelToken, now, prematchEndTime, sportId) => {
	sportId = sportId || nSoftPrematch.defaultSportId;

	const params = {
		'filter[from]': now,
		'filter[to]': prematchEndTime,
		'filter[sportId]': sportId,
		timezone: 'Europe/Bucharest',
		language: {
			default: getLanguage(),
			events: getLanguage(),
			category: getLanguage(),
			sport: getLanguage(),
			tournament: getLanguage(),
			market: getLanguage(),
			marketGroup: getLanguage()
		},
		dataFormat: {
			default: 'object',
			events: 'array',
			markets: 'array',
			outcomes: 'array'
		},
		companyUuid: nSoftPrematch.companyUuid,
		deliveryPlatformId: 3
		//marketCount: 10,
	};

	if (nSoftPrematch.shortProps) {
		params['shortProps'] = nSoftPrematch.shortProps;
	}

	return axios.get(nSoftPrematch.apiURL + '/api/v1/events', {
		params,
		cancelToken
	});
};

let pWorker = null;

function* prematchInitializeSagaWeb() {
	let bst = getBetsState(getStore().getState());

	debug('prematch initialize');

	const now = moment();
	const snow = now.format('YYYY-MM-DDTHH:mm:ss');

	const prematchEndDay = moment()
		.add(1, 'day')
		.startOf('day')
		// change needed to accommodate the task https://boongaming.atlassian.net/browse/PO-5751
		.add(HOURS_OFFSET, 'hours')
		.format('YYYY-MM-DDTHH:mm:ss');
	const prematchEndMonth = moment()
		.add(1, 'month')
		.startOf('day')
		.subtract(1, 'second')
		.format('YYYY-MM-DDTHH:mm:ss');

	const dayInfo = {
		startDate: now.startOf('day').valueOf(),
		idSport: nSoftPrematch.defaultSportId,
		days: 1
	};

	yield getStore().dispatch(prematchSetLoadingState(dayInfo));
	let init = true;

	try {
		if (!bst.prematch.fullStateLoaded) {
			debug('digitain load prematch state');
			const [meta, topOffer, events] = yield Promise.all([
				prematch.fetchMeta(null, snow, prematchEndMonth),
				prematch.fetchTopOffer(null, snow, prematchEndDay),
				prematch.fetchEvents(null, snow, prematchEndDay, nSoftPrematch.defaultSportId)
			]);

			debug('set prematch state');
			yield setTimeout(() => {
				getStore().dispatch(
					prematchSetState(
						dayInfo,
						meta.data.data,
						topOffer.data.data,
						events.data.data,
						nSoftPrematch.shortProps
					)
				);

				bst = getBetsState(getStore().getState());

				// load events present in day multi bet
				debug('[dayMultiBets]', bst.config.dayMultiBets);

				if (bst.config.dayMultiBets && Array.isArray(bst.config.dayMultiBets)) {
					const eventsToLoad = {};
					for (const dmb of bst.config.dayMultiBets) {
						for (const st of dmb.stakes) {
							eventsToLoad[st.event_id] = true;
						}
					}

					const evs = Object.keys(eventsToLoad);

					debug(`[dayMultiBets] load ${evs.length} events`, evs);

					if (evs.length !== 0) {
						getStore().dispatch(prematchFetchMatches(evs));
					}
				}
			}, 0);
			debug('load betslip');
			yield setTimeout(() => getStore().dispatch(betsSlipLoad()), 0);
			debug('connect prematch socket');
			if (!window.config.cordova) yield connect();
			debug('connect calculator');
			yield connectTicketCalculator();
		}

		createIntervalTicker({
			name: 'prematch cleanup',
			interval: 60 * 1000,
			add: 2 * 1000,
			callback: () => {
				//console.log("prematch cleanup at", new Date());
				getStore().dispatch(prematchClearExpired());
			},
			options: {
				replace: true,
			},
		});
	} catch (e) {
		console.log('Error fetching prematch ', e);
		getStore().dispatch(appSaveErrorLog('', 'Error fetching prematch', JSON.stringify(e)));
	} finally {
		yield getStore().dispatch(prematchSetLoadingState(null));
	}
}

function* prematchReinitializeSagaWeb() {
	let bst = getBetsState(getStore().getState());

	debug('prematch reinitialize');

	const now = moment();
	const snow = now.format('YYYY-MM-DDTHH:mm:ss');

	const prematchEndDay = moment()
		.add(1, 'day')
		.startOf('day')
		.subtract(1, 'second')
		.format('YYYY-MM-DDTHH:mm:ss');
	const prematchEndMonth = moment()
		.add(1, 'month')
		.startOf('day')
		.subtract(1, 'second')
		.format('YYYY-MM-DDTHH:mm:ss');

	const dayInfo = {
		startDate: now.startOf('day').valueOf(),
		idSport: nSoftPrematch.defaultSportId,
		days: 1
	};

	try {
		debug('reload prematch meta');
		const [meta] = yield Promise.all([
			prematch.fetchMeta(null, snow, prematchEndMonth),
		]);
		getStore().dispatch(
			prematchSetState(
				dayInfo,
				meta.data.data,
				null,
				null,
				nSoftPrematch.shortProps
			)
		);

		let idSport = bst.navSports.prematch;
		if (idSport === '0') {
			idSport = nSoftPrematch.defaultSportId;
		}

		let day = bst.calendar.filterBy;
		if (day === null) {
			day = moment().valueOf();
		}

		day = parseInt(day, 10);

		debug('reinit day', day, 'idSport', idSport);

		yield put(
			prematchFetchSportByDay({
				day,
				noDays: 1,
				idSport,
				skipLoadingState: true,
			})
		);

		bst = getBetsState(getStore().getState());

		// load events present in day multi bet
		debug('[dayMultiBets]', bst.config.dayMultiBets);

		if (bst.config.dayMultiBets && Array.isArray(bst.config.dayMultiBets)) {
			const eventsToLoad = {};
			for (const dmb of bst.config.dayMultiBets) {
				for (const st of dmb.stakes) {
					eventsToLoad[st.event_id] = true;
				}
			}

			const evs = Object.keys(eventsToLoad);

			debug(`[dayMultiBets] load ${evs.length} events`, evs);

			if (evs.length !== 0) {
				yield put(prematchFetchMatches(evs));
			}
		}
	} catch (e) {
		console.log('Error re-fetching prematch ', e);
		getStore().dispatch(appSaveErrorLog('', 'Error re-fetching prematch', JSON.stringify(e)));
	} finally {
		debug('prematch reinitialize finish');
		yield getStore().dispatch(prematchSetLoadingState(null));
	}
}

const mergeMatches = matches => {
	if (matches !== null) {
		getStore().dispatch(prematchMergeMatches(matches));
	}
};

function* prematchInitializeSagaCordova() {
	let bst = getBetsState(getStore().getState());
	if (bst.prematch.fullStateLoaded) return;
	if (pWorker !== null) return;

	debug('prematch2 initialize');

	try {
		const worker = new Worker(new URL('./digitain/index.js', import.meta.url), { type: "module" }); // eslint-disable-line
		pWorker = wrap(worker);

		debug('worker', worker, 'pWorker', pWorker);

		const global = {
			config: window.config,
			location: { search: window.location.search },
		};

		const [dayInfo, state] = yield pWorker.initializePrematch(proxy(mergeMatches), false, 'en', global);

		debug('worker prematch state', state);

		yield put(prematchMergeState(dayInfo, state));

		bst = getBetsState(getStore().getState());

		// load events present in day multi bet
		debug('[dayMultiBets]', bst.config.dayMultiBets);

		if (bst.config.dayMultiBets && Array.isArray(bst.config.dayMultiBets)) {
			const eventsToLoad = {};
			for (const dmb of bst.config.dayMultiBets) {
				for (const st of dmb.stakes) {
					eventsToLoad[st.event_id] = true;
				}
			}

			const evs = Object.keys(eventsToLoad);

			debug(`[dayMultiBets] load ${evs.length} events`, evs);

			if (evs.length !== 0) {
				yield put(prematchFetchMatches(evs));
			}
		}

		debug('load betslip');
		setTimeout(() => getStore().dispatch(betsSlipLoad()), 0);
		//debug("connect prematch socket");
		//connect();
		debug('connect calculator');
		connectTicketCalculator();

		createIntervalTicker({
			name: 'prematch cleanup',
			interval: 60 * 1000,
			add: 2 * 1000,
			callback: () => {
				//console.log("prematch cleanup at", new Date());
				getStore().dispatch(prematchClearExpired());
			},
			options: {
				replace: true,
			},
		});
	} catch (e) {
		console.log('Error fetching worker prematch ', e);
		getStore().dispatch(appSaveErrorLog('', 'Error fetching prematch', JSON.stringify(e)));
	} finally {
		debug('prematch initialize finish');
		yield getStore().dispatch(prematchSetLoadingState(null));
	}
}

function* prematchReinitializeSagaCordova() {
	let bst = getBetsState(getStore().getState());

	try {
		const [dayInfo, state] = yield pWorker.initializePrematch(proxy(mergeMatches), true);
		yield put(prematchMergeState(dayInfo, state));

		let idSport = bst.navSports.prematch;
		if (idSport === '0') {
			idSport = nSoftPrematch.defaultSportId;
		}

		let day = bst.calendar.filterBy;
		if (day === null) {
			day = moment().valueOf();
		}

		day = parseInt(day, 10);

		debug('reinit day', day, 'idSport', idSport);

		yield put(
			prematchFetchSportByDay({
				day,
				noDays: 1,
				idSport,
				skipLoadingState: true,
			})
		);

		bst = getBetsState(getStore().getState());

		// load events present in day multi bet
		debug('[dayMultiBets]', bst.config.dayMultiBets);

		if (bst.config.dayMultiBets && Array.isArray(bst.config.dayMultiBets)) {
			const eventsToLoad = {};
			for (const dmb of bst.config.dayMultiBets) {
				for (const st of dmb.stakes) {
					eventsToLoad[st.event_id] = true;
				}
			}

			const evs = Object.keys(eventsToLoad);

			debug(`[dayMultiBets] load ${evs.length} events`, evs);

			if (evs.length !== 0) {
				yield put(prematchFetchMatches(evs));
			}
		}
	} catch (e) {
		console.log('Error fetching worker prematch ', e);
		getStore().dispatch(appSaveErrorLog('', 'Error fetching prematch', JSON.stringify(e)));
	} finally {
		debug('prematch initialize finish');
		yield getStore().dispatch(prematchSetLoadingState(null));
	}
}

function* changeLanguageInWorker(action) {
	if (pWorker === null) return;
	//console.log('changeLanguageInWorker', action);
	try {
		yield pWorker.changeLanguage(action.language);
	} catch (e) {
		// noop
	}

}

// Websocket

let prematchSocket = null;
let mergeInterval = 0;

let prematchTicketSocket = null;
let tempMatches = {};

const connect = () => {
	return;
};

let pendingTickets = {};

function* prematchConnectTicketSocketSaga(action) {
	if (prematchTicketSocket !== null) {
		//console.log("prematch ticket socket already connected, disconnecting");
		yield prematchTicketSocket.disconnect();
		prematchTicketSocket = null;
	}

	const socket = yield openSocket(nSoftPrematch.ticketWsURL, {
		forceNew: true,
		transports: ['websocket'],
		query: {
			companyUuid: nSoftPrematch.companyUuid
		}
	});

	var onevent = socket.onevent;
	socket.onevent = function (packet) {
		var args = packet.data || [];
		onevent.call(this, packet); // original call
		packet.data = ['*'].concat(args);
		onevent.call(this, packet); // additional call to catch-all
	};

	//console.log("prematch ticket socket", socket);

	/*
	let keepAliveInterval;
	const keepAlive = () => {
		socket.emit("message", {
			type: "serviceAlive"
		});
	};
	*/

	const register = () => {
		//console.log("Registering...");

		const bst = getBetsState(getStore().getState());

		if (bst.auth.details !== null) {
			//console.log("ticket socket auth register");
			socket.emit('registration', {
				type: 'Punter',
				data: {
					routingKey,
					punterUuid: bst.auth.details.Uuid
				}
			});
		} else {
			//console.log("ticket socket guest register");
			socket.emit('registration', {
				type: 'Web',
				data: {
					routingKey
				}
			});
		}

		/*
		clearInterval(keepAliveInterval);
		keepAliveInterval = setInterval(keepAlive, 10000);
		*/
	};

	socket.on('connect', () => {
		//console.log("Connected");

		register();
	});

	socket.on('error', error => {
		//console.log("Socket error", error);
	});

	socket.on('connect_error', error => {
		//console.log("Socket connect error", error);
	});

	socket.on('*', (...data) => {
		//console.log("ticket socket data", ...data);
	});

	socket.on('ticketAccepted', res => {
		//console.log("prematch ticket accepted", res);

		const requestUuid = res.data.ticketRequest.requestUuid;

		if (requestUuid in pendingTickets) {
			//console.log("clear pending interval");
			clearInterval(pendingTickets[requestUuid]);
			delete pendingTickets[requestUuid];
		}

		const t = res.data;

		const newTicket = {
			ticketId: t.id,
			normalTicketHash: t.ticketHashes[0].hash,
			type: t.type.toUpperCase(),
			status: t.status.name,
			maxWinnings: t.winningAmountMax,
			oddMax: t.oddMax,
			payment: t.paymentAmount,
			createdAt: t.createdAtMs,
			units: t.ticketUnits,
			bets: []
		};

		newTicket.bets = t.ticketBets.map(b => ({
			resolutionStatus: 'OPEN',
			startAt: b.event.startsAt,
			eventId: b.event.id,
			marketId: b.market.id,
			eventMarketId: b.eventMarketId,
			eventMarketOutcomeId: b.eventMarketOutcomeId,
			marketName: b.market.name,
			marketOutcomeName: b.marketOutcome.name,
			odd: b.odd,
			eventCompetitors: b.eventCompetitors,
			eventMarketCompetitors: b['eventMarketCompetitors'] ? b['eventMarketCompetitors'] : [],
			results: []
		}));

		const bst = getBetsState(getStore().getState());

		getStore().dispatch(betsSlipPrematchCreateTicketSuccess());

		const nt = normalizePreMatchTickets([newTicket]);

		if (bst.app.isWinnerFun) {
			getStore().dispatch(ticketWinnerFunStoreOpenPrepare(nt));
		} else {
			getStore().dispatch(ticketStoreOpen(nt));
		}

		// no longer needed. expecting message via player websocket
		//getStore().dispatch(requestWallet());
	});

	socket.on('ticketRejected', res => {
		//console.log("prematch ticket rejected", res);
		getStore().dispatch(appSaveErrorLog('', 'Prematch ticket rejected', JSON.stringify(res)));

		let err = 'Error creating prematch ticket';
		let e = null;
		let details = [];

		if (typeof res.errors !== 'undefined' && isArray(res.errors)) {
			e = res.errors[0];
			err = `${e.detail}`;

			if (e.code === 101) {
				err = 'Admin rejected ticket';
			} else if (e.code === 403) {
				err = 'Cannot place ticket';
			}

			if (
				e.data &&
				e.data.riskManagement &&
				e.data.riskManagement.rules &&
				isArray(e.data.riskManagement.rules)
			) {
				details = e.data.riskManagement.rules.map(r => r.message);
			}
		}

		getStore().dispatch(betsSlipPrematchCreateTicketError(err, details));

		if (e !== null) {
			const requestUuid = e.data.ticketRequest.requestUuid;

			if (requestUuid in pendingTickets) {
				//console.log("clear pending interval");
				clearInterval(pendingTickets[requestUuid]);
				delete pendingTickets[requestUuid];
			}
		}
	});

	socket.on('ticketReOffered', res => {
		//console.log("prematch reoffered ticket", res);

		getStore().dispatch(betsSlipPrematchCreateTicketReoffer(res.data));

		const requestUuid = res.data.originalTicket.ticketRequest.requestUuid;

		if (requestUuid in pendingTickets) {
			//console.log("clear pending interval");
			clearInterval(pendingTickets[requestUuid]);
			delete pendingTickets[requestUuid];
		}
	});

	socket.on('codeTemplateTicketAccepted', res => {
		const th = res.data.ticketHashes[0];

		getStore().dispatch(betsSlipPrematchCreateTicketSuccess(th.hash));
		getStore().dispatch(appSaveReservedTicket('prematch', res.data));
	});

	socket.on('codeTemplateTicketRejected', res => {
		getStore().dispatch(
			appSaveErrorLog('', 'Prematch shop ticket rejected', JSON.stringify(res))
		);

		let err = 'Error creating prematch ticket';
		let e = null;
		let details = [];

		if (typeof res.errors !== 'undefined' && isArray(res.errors)) {
			e = res.errors[0];
			err = `${e.detail}`;

			if (e.code === 101) {
				err = 'Admin rejected ticket';
			} else if (e.code === 403) {
				err = 'Cannot place ticket';
			}

			if (
				e.data &&
				e.data.riskManagement &&
				e.data.riskManagement.rules &&
				isArray(e.data.riskManagement.rules)
			) {
				details = e.data.riskManagement.rules.map(r => r.message);
			}
		}

		getStore().dispatch(betsSlipPrematchCreateTicketError(err, details));
	});

	prematchTicketSocket = socket;
}

/*
function* prematchAuthTicketSocketSaga(action) {
	console.log("auth prematch ticket saga", action);

	if (prematchTicketSocket) {
		prematchTicketSocket.emit("registration", {
			type: "Punter",
			data: {
				routingKey,
				punterUuid: action.punterUuid
			}
		});

		return;
	}

	console.log("prematch ticket socket not connected");
}
*/

let prematchTicketCalculatorSocket = null;
let prematchTicketCalculatorRegistered = false;
let prematchEvalTicketAssoc = {};

const connectTicketCalculator = () => {
	return;
	/*
	debug('connecting ticket calculator');

	if (prematchTicketCalculatorSocket !== null) {
		debug('calculator already connected');
		return;
	}

	const socket = openSocket(nSoftPrematch.ticketCalculatorUrl, {
		forceNew: true,
		transports: ['websocket'],
		query: {
			companyUuid: nSoftPrematch.companyUuid
		}
	});

	var onevent = socket.onevent;
	socket.onevent = function (packet) {
		var args = packet.data || [];
		onevent.call(this, packet); // original call
		packet.data = ['*'].concat(args);
		onevent.call(this, packet); // additional call to catch-all
	};

	//console.log("prematch ticket calculator socket", socket);

	let rcTid = setTimeout(() => {
		socket.disconnect();
		prematchTicketCalculatorSocket = null;
		connectTicketCalculator();
	}, 3000);

	socket.on('connect', () => {
		//console.log("calculator Connected");

		prematchTicketCalculatorRegistered = false;

		socket.emit('registration', {
			type: 'Retail',
			data: {
				deviceUuid: 'calculationDevice'
			}
		});
	});

	socket.on('error', error => {
		//console.log("calculator Socket error", error);
	});

	socket.on('connect_error', error => {
		//console.log("calculator Socket connect error", error);
	});

	socket.on('*', (...data) => {
		//console.log("calculator ticket socket data", ...data);
	});

	socket.on('registrationResult', data => {
		debug('got ticket calculator registration result', data);

		clearTimeout(rcTid);

		const d = JSON.parse(data);

		if (d.data) {
			prematchTicketCalculatorRegistered = true;
			debug('computing win');
			getStore().dispatch(betsSlipComputeWin());
		}
	});

	socket.on('calculateTicketResult', data => {
		let index = null;
		let requestUuid = null;

		if (data.data && data.data.ticketRequest && data.data.ticketRequest.requestUuid) {
			//console.log("got eval requestUuid", data.data.ticketRequest.requestUuid, "assoc", prematchEvalTicketAssoc);
			requestUuid = data.data.ticketRequest.requestUuid;
		}

		if (requestUuid !== null) {
			if (requestUuid in prematchEvalTicketAssoc) {
				index = prematchEvalTicketAssoc[requestUuid];
				delete prematchEvalTicketAssoc[requestUuid];
			} else {
				console.log('ticket eval requestUuid with no index', requestUuid);
			}
		}

		getStore().dispatch(betsSlipPrematchEvalTicketData(data.data, index));
	});

	prematchTicketCalculatorSocket = socket;
	*/
};

// eslint-disable-next-line
function* prematchDisconnectTicketSocketSaga() {
	if (prematchTicketSocket) {
		prematchTicketSocket.disconnect();
		prematchTicketSocket = null;
	}
}

export const prematchDisconnect = () => {
	if (prematchSocket) {
		prematchSocket.disconnect();
		prematchSocket = null;

		clearInterval(mergeInterval);
		mergeInterval = 0;
	}

	/*
	if (prematchTicketSocket) {
		prematchTicketSocket.disconnect();
		prematchTicketSocket = null;
	}
	*/
};

function* prematchEvalTicketSaga(action) {
	const { auth, betsSlip } = getBetsState(getStore().getState());

	if (prematchTicketCalculatorSocket === null || !prematchTicketCalculatorRegistered) {
		debug('calculator not available');
		return;
	}

	//console.log("eval betsSlip", betsSlip, action);

	/*
	const ticketBets = betsSlip.prematch.selected.reduce((acc, s) => {
		console.log("s", s);
		return acc.concat({
			eventMarketOutcomeId: parseInt(s.idMbo, 10),
			eventId: parseInt(s.idMatch, 10),
			eventMarketId: parseInt(s.idMb, 10),
			marketId: parseInt(s.idBet, 10),
			marketOutcomeId: parseInt(s.idBo, 10),
			odd: s.odd
		});
	}, []);

	const ticketUnits = betsSlip.prematch.selected.reduce((acc, s, i) => {
		return acc.concat({
			ticketBetIndex: i,
			banker: false,
			ways: false
		});
	}, []);

	const evalReq = {
		requestUuid: uuidv4(),
		calculateSelectedSystems: true,
		calculateWays: false,
		language: {
			default: getLanguage()
		},
		ticket: {
			payment: {
				value: betsSlip.stake,
				type: "total"
			},
			type: "Combo",
			ticketBets: ticketBets,
			ticketUnits: [
				{
					id: uuidv4(),
					selectedSystems: [1],
					ticketUnitBets: ticketUnits
				}
			]
		}
	};
	*/

	const ct = betsSlip.tickets[action.index];
	const bsPrematch = ct.prematch;

	//debug("betSlip", betsSlip, "ct", ct, "action", action);

	const requestUuid = uuidv4();

	//console.log("prematch eval betsSlip", bsPrematch, auth);

	const ticketBets = bsPrematch.selected.reduce((acc, s) => {
		if (!s.valid) {
			return acc;
		}

		return acc.concat({
			eventMarketOutcomeId: parseInt(s.idMbo, 10),
			eventId: parseInt(s.idMatch, 10),
			eventMarketId: parseInt(s.idMb, 10),
			marketId: parseInt(s.idBet, 10),
			marketOutcomeId: parseInt(s.idBo, 10),
			odd: s.odd
		});
	}, []);

	let bankers = 0;

	const ticketUnitBets = bsPrematch.selected.reduce((acc, s, i) => {
		if (!s.valid) {
			return acc;
		}

		if (s.fixed) {
			bankers++;
		}

		return acc.concat({
			ticketBetIndex: i,
			banker: s.fixed,
			ways: false
		});
	}, []);

	const ticketType = ct.ticketType === 'system' ? 'System' : 'Combo';

	let selectedSystems = [bsPrematch.selected.length - bankers];

	if (ct.ticketType === 'system') {
		selectedSystems = bsPrematch.systems;
	}

	//const routingKey = uuidv4();

	let sources = [
		{
			type: 'productInstance',
			uuid: nSoftPrematch.productInstanceUuid
		}
	];

	if (auth.details) {
		sources.push({
			type: 'player',
			uuid: auth.details.Uuid
		});
	}

	let ticketUnits = [
		{
			selectedSystems,
			ticketUnitBets
		}
	];

	if (ct.ticketType === 'system') {
		ticketUnits = [];

		selectedSystems.forEach(s => {
			ticketUnits.push({
				selectedSystems: [s],
				ticketUnitBets
			});
		});
	}

	const evalReq = {
		deliveryPlatform: ct.ticketOnline ? 'Web' : 'Retail',
		calculateSelectedSystems: ct.ticketType !== 'system',
		calculateWays: true,
		language: getLanguage(),
		ticket: {
			payment: {
				value: ct.amount,
				type: 'total'
			},
			type: ticketType,
			ticketBets,
			ticketUnits
		}
	};

	const evalReqStr = JSON.stringify(evalReq);

	if (evalReqStr === ct.evalStr) {
		//debug("same ticket eval");
		return;
	}

	yield put(betsSlipPrematchEvalTicketStr(evalReqStr, action.index));

	evalReq['requestUuid'] = requestUuid;

	prematchEvalTicketAssoc[requestUuid] = action.index;

	//console.log("calculator", evalReq);

	yield prematchTicketCalculatorSocket.emit('calculateTicket', evalReq);
}

function* prematchCreateTicketSaga(action) {
	const { auth, betsSlip, app, config } = getBetsState(getStore().getState());

	const ct = betsSlip.tickets[betsSlip.currentTicket];
	const bsPrematch = ct.prematch;
	const isWinnerFun = app.isWinnerFun ? true : false;
	const winnerFunData = activeWinnerFunCampaigns(config.winnerFunCampaigns, true);
	const free_bet_code = action.free_bet_code ? action.free_bet_code : null;
	const free_bet_name = action.free_bet_name ? action.free_bet_name : null;
	const free_bet_pid = action.free_bet_pid ? action.free_bet_pid : null;
	const free_bet_redeemable = action.free_bet_redeemable ? action.free_bet_redeemable : null;


	if (ct.useWinnerFunBoost) {
		winnerFunData['use_boost'] = true;
	}

	const requestUuid = uuidv4();

	//console.log("prematch create betsSlip", bsPrematch, auth);

	if (bsPrematch.selected.length === 0) {
		yield put(betsSlipPrematchCreateTicketSuccess(betsSlip.currentTicket));
		return;
	}

	const ticketBets = bsPrematch.selected.reduce((acc, s) => {
		if (!s.valid) {
			return acc;
		}

		return acc.concat({
			eventMarketOutcomeId: parseInt(s.idMbo, 10),
			odd: s.odd
		});
	}, []);

	let bankers = 0;

	const ticketUnits = bsPrematch.selected.reduce((acc, s, i) => {
		if (!s.valid) {
			return acc;
		}

		if (s.fixed) {
			bankers++;
		}

		return acc.concat({
			ticketBetIndex: i,
			banker: s.fixed
			//ways: false
		});
	}, []);

	const ticketType = ct.ticketType === 'system' ? 'System' : 'Combo';

	let selectedSystems = [bsPrematch.selected.length - bankers];

	if (ct.ticketType === 'system') {
		selectedSystems = bsPrematch.systems;
	}

	//const routingKey = uuidv4();

	let sources = [
		{
			type: 'productInstance',
			uuid: nSoftPrematch.productInstanceUuid
		}
	];

	if (auth.details) {
		sources.push({
			type: 'player',
			uuid: auth.details.Uuid
		});
	}

	const createReq = {
		metadata: {
			product: nSoftPrematch.productName,
			deliveryPlatform: 'Web',
			cpvUuid: nSoftPrematch.cpvUuid,
			paymentMethod: 'VirtualMoney',
			requestUuid: requestUuid,
			sources: sources
		},
		ticket: {
			routingKey: {
				value: routingKey
			},
			payment: {
				value: ct.amount,
				type: 'total'
			},
			type: ticketType,
			ticketBets: ticketBets,
			ticketUnits: [
				{
					selectedSystems: selectedSystems,
					ticketUnitBets: ticketUnits
				}
			],
			language: {
				default: getLanguage()
			},
			ticketComment: app.isWinnerFun ? 'winner_fun' : 'winner',
		},
	};

	if (isWinnerFun) {
		createReq.clientVal = JSON.stringify(winnerFunData);
	} else {
		if (free_bet_code) {
			createReq.clientVal = JSON.stringify({ free_bet_code, free_bet_name, free_bet_pid, free_bet_redeemable });
		}
	}

	/*
	const ticket = {
		ticket: {
			payment: { value: 20, type: "total" },
			routingKey: { value: "8c7eaa1e-9cf4-4af6-88b4-7866434666fd" },
			type: "Combo",
			ticketBets: [
				{ eventMarketOutcomeId: 141722286, odd: 2.04 },
				{ eventMarketOutcomeId: 141722310, odd: 1.7 }
			],
			ticketUnits: [
				{
					selectedSystems: [2],
					ticketUnitBets: [
						{ ticketBetIndex: 0, banker: false, ways: false },
						{ ticketBetIndex: 1, banker: false, ways: false }
					]
				}
			],
			language: { default: "en" }
		},
		metadata: {
			cpvUuid: "1072b9b0-d247-41a2-b64d-967ce5697e54",
			requestUuid: "05598293-ebbd-438e-b4ca-3eb668a7b1bd",
			deliveryPlatform: "Web",
			paymentMethod: "VirtualMoney",
			product: "SportsbookSM",
			sources: [
				{ type: "player", uuid: "3b53ea38-e708-47ee-b419-4c761861bb35" },
				{
					type: "productInstance",
					uuid: "3341d17a-44f0-48b2-8b3c-2c5378ba476b"
				}
			]
		}
	};
	*/

	try {
		//console.log("ticket type", ct.ticketOnline ? "online" : "shop", auth);

		if (ct.ticketOnline) {
			if (auth.details === null) {
				// t("You are not authenticated")
				yield put(betsSlipPrematchCreateTicketError('You are not authenticated'));
				return;
			}

			const result = yield axios.post(
				nSoftPrematch.ticketsUrl + '/web/tickets/add.json?requestUuid=' + requestUuid,
				createReq,
				{
					headers: {
						Authorization: 'Bearer ' + auth.details.accessToken,
						'SEVEN-TP-TOKEN': auth.details.tpToken,
						//companyUuid: ticketCompanyUuid,
						'SEVEN-LOCALE': getLanguage(),
						'HTTP-X-NAB-DP': 'Web',
						'HTTP-X-SEVEN-CLUB-UUID': nSoftPrematch.companyUuid,
						'HTTP-X-NAB-PRODUCTNAME': nSoftPrematch.productName,
						'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftPrematch.productInstanceUuid
					}
				}
			);

			//console.log("prematch ticket result", result);

			const ptid = setInterval(() => {
				checkTicket(auth, requestUuid);
			}, 10000);

			pendingTickets[requestUuid] = ptid;
		} else {
			const result = yield axios.post(
				//nSoftCommon.apiURL +
				//nSoftTicket.apiURL +
				nSoftPrematch.gatewayUrl +
				//"/ticketCode.php/prematch/ticketCode?requestUuid=" +
				//"/api/code-template-tickets?requestUuid=" +
				'/code-template-tickets?requestUuid=' +
				requestUuid +
				'&companyUuid=' +
				nSoftPrematch.companyUuid,
				createReq,
				{
					headers: {
						companyUuid: nSoftPrematch.companyUuid,
						//Authorization: "Bearer " + auth.details.accessToken,
						//"SEVEN-TP-TOKEN": auth.details.tpToken,
						'SEVEN-LOCALE': getLanguage(),
						'X-NSFT-SEVEN-COMPANY-UUID': nSoftPrematch.companyUuid
						//"HTTP-X-NAB-DP": "Web"
						//"HTTP-X-SEVEN-CLUB-UUID": nSoftPrematch.companyUuid,
						//"HTTP-X-NAB-PRODUCTNAME": nSoftPrematch.productName,
						//"HTTP-X-NAB-PRODUCTINSTANCE-ID": nSoftPrematch.productInstanceUuid
					}
				}
			);

			//console.log("prematch shop ticket result", result);
		}

		//yield put(betsSlipPrematchCreateTicketPending(requestUuid));
	} catch (e) {
		let err = e.toString();
		yield put(appSaveErrorLog(JSON.stringify(createReq), err, JSON.stringify(e)));

		err = 'Error creating prematch ticket';
		let details = [];

		if (e.response) {
			if ('status' in e.response && e.response.status === 499) {
				err = 'Not enough money';
			} else {
				//console.log("error response", e.response);
				//err = `HTTP code: ${e.response.data.httpCode}, code: ${e.response.data.code}, message: ${e.response.data.message}`;
				//err = `${e.response.data.message} (${e.response.data.httpCode}, ${e.response.data.code})`;

				if (
					typeof e.response.data.errors !== 'undefined' &&
					isArray(e.response.data.errors)
				) {
					let e = e.response.data.errors[0];
					err = `${e.detail}`;

					if (e.code === 101) {
						err = 'Admin rejected ticket';
					} else if (e.code === 403) {
						err = 'Cannot place ticket';
					}

					if (
						e.data &&
						e.data.riskManagement &&
						e.data.riskManagement.rules &&
						isArray(e.data.riskManagement.rules)
					) {
						details = e.data.riskManagement.rules.map(r => r.message);
					}
				}
			}
		}

		yield put(betsSlipPrematchCreateTicketError(err, details));
	}
}

const checkTicket = (auth, requestUuid) => {
	axios
		.get(
			nSoftPrematch.ticketsUrl +
			'/web/tickets/request/' +
			requestUuid +
			'/product/' +
			nSoftPrematch.productName +
			'/check.json',
			{
				headers: {
					Authorization: 'Bearer ' + auth.details.accessToken,
					'SEVEN-TP-TOKEN': auth.details.tpToken,
					//companyUuid: ticketCompanyUuid,
					'SEVEN-LOCALE': getLanguage(),
					'HTTP-X-NAB-DP': 'Web',
					'HTTP-X-SEVEN-CLUB-UUID': nSoftPrematch.companyUuid,
					'HTTP-X-NAB-PRODUCTNAME': nSoftPrematch.productName,
					'HTTP-X-NAB-PRODUCTINSTANCE-ID': nSoftPrematch.productInstanceUuid
				}
			}
		)
		.then(res => {
			//console.log("prematch check ticket result", res);

			if (res.data.status.id.toLowerCase() === 'pending') {
				// wait more
				return;
			}

			if (requestUuid in pendingTickets) {
				//console.log("clear pending interval");
				clearInterval(pendingTickets[requestUuid]);
				delete pendingTickets[requestUuid];
			} else {
				//console.log("pending interval not found", requestUuid);
			}

			if (res.data.status.id.toLowerCase() === 'rejected') {
				// place ticket failed
				// t("Your ticket has been rejected");
				getStore().dispatch(betsSlipPrematchCreateTicketError('Admin rejected ticket'));
				return;
			}

			const bst = getBetsState(getStore().getState());

			getStore().dispatch(betsSlipPrematchCreateTicketSuccess());

			const nt = normalizePreMatchTickets([res.data]);

			if (bst.app.isWinnerFun) {
				getStore().dispatch(ticketWinnerFunStoreOpenPrepare(nt));
			} else {
				getStore().dispatch(ticketStoreOpen(nt));
			}

			//getStore().dispatch(ticketStoreOpen(normalizePreMatchTickets([res.data], bst)));

			// no longer needed. expecting message via player websocket
			//getStore().dispatch(requestWallet());
		})
		.catch(e => {
			//console.log("prematch check ticket error", e);
			getStore().dispatch(
				appSaveErrorLog(
					JSON.stringify({ requestUuid }),
					'Error checking prematch ticket',
					JSON.stringify(e)
				)
			);
		});
};

function* fetchEventSaga(action) {
	debug('fetch event', action.idMatch);

	/*
	const bst = getBetsState(getStore().getState());

	if (!(action.idMatch in bst["prematch"]["matches"])) {
		//debug(`match ${action.idMatch} not found in state`);
		return;
	}

	if (bst["prematch"]["matches"][action.idMatch].brId !== null) {
		debug(`match ${action.idMatch} has BR ID`);
		return;
	}

	const now = moment().format();
	const prematchEndTime = moment()
		.add(1, "month")
		.startOf("day")
		.subtract(1, "second");
	*/

	const { data } = yield prematch.fetchEventsById(null, action.idMatch);

	debug(`fetch event ${action.idMatch}`, data.data.events[0]);

	data.data.events[0]['_loaded'] = true;

	yield put(prematchSetMatch(data.data.events[0]));
}

function* fetchEventsSaga(action) {
	debug(`fetch events with idRequest ${action.idRequest}`, action.idMatch);

	/*
	const bst = getBetsState(getStore().getState());

	if (!(action.idMatch in bst["prematch"]["matches"])) {
		//debug(`match ${action.idMatch} not found in state`);
		return;
	}

	if (bst["prematch"]["matches"][action.idMatch].brId !== null) {
		debug(`match ${action.idMatch} has BR ID`);
		return;
	}

	const now = moment().format();
	const prematchEndTime = moment()
		.add(1, "month")
		.startOf("day")
		.subtract(1, "second");
	*/

	try {
		let matchData = [];

		if (action.idMatch && Array.isArray(action.idMatch) && action.idMatch.length > 0) {
			const { data } = yield prematch.fetchEventsById(null, action.idMatch);

			matchData = data.data.events;
		}

		// if this is a betslip load request, filter matches that have already begun
		if (action.idRequest && (action.idRequest.indexOf('betslip') === 0 || action.idRequest === 'favorites')) {
			debug('filtering betslip matches');

			const now = moment().valueOf();

			matchData = matchData.filter(m => {
				if (typeof m.idMatch !== 'undefined') {
					return stdMatchStartTime(m, null, 'matchDateTime') >= now;
				}
				return false;
			});
		}

		for (const m of matchData) {
			m['_loaded'] = true;
		}

		yield put(prematchSetMatches(matchData, action.idRequest));
		// yield put(ticketSetBetradarId());

		if (action.idRequest && action.idRequest.indexOf('betslip') === 0) {
			yield put(betsSlipPrematchMatchesLoaded(action.idRequest));
		}
	} catch (e) {
		console.error('failed to fetch prematch events', action.idMatch, e);
	}
}

function* fetchTournamentEventsSaga(action) {
	if (!nSoftPrematch.marketCount) {
		debug('all markets loaded');
	}

	debug('fetchTournamentEventsSaga', action.tournamentId);

	const { prematch } = getBetsState(getStore().getState());

	if (!prematch.matches) {
		debug('no matches');
		return;
	}

	const ms = Object.values(prematch.matches).filter(m => m.idTournament === action.tournamentId && !m['_loaded']);

	debug('ms', ms);

	if (ms.length > 0) {
		const mids = ms.map(m => m.idMatch);

		yield fetchEventsSaga({ idMatch: mids });
	}
}

let cancelToken = null;

function* prematchFetchSportByDaySaga(action) {
	debug('*** prematchFetchSportByDaySaga', action);

	const bst = getBetsState(getStore().getState());

	if (!bst.prematch.fullStateLoaded) {
		return;
	}

	if (action.data && action.data.idSport === 0) {
		return;
	}

	const excludeMarkets = bst.prematch.fullStateLoaded;

	//console.log("bst", bst);

	let startDate, days;

	if (action.data.day === 0) {
		startDate = moment();
		days = 31;
	} else {
		startDate = moment(parseInt(action.data.day, 10));
		days = action.data.noDays || 1;
	}

	debug('startDate', startDate);

	const now = moment().valueOf();
	if (startDate.valueOf() < now) {
		debug('reinit startDate to now');
		startDate = moment();
	}

	const sds = startDate.format('YYYY-MM-DDTHH:mm:ss');

	const dayInfo = {
		startDate: startDate.startOf('day').valueOf(),
		days,
		idSport: action.data.idSport
	};

	const prematchEndTime = startDate.clone()
		.add(days, 'day')
		.startOf('day')
		.subtract(1, 'second')
		// change needed to accommodate the task https://boongaming.atlassian.net/browse/PO-5751
		.add(HOURS_OFFSET, 'hours')
		.format('YYYY-MM-DDTHH:mm:ss');

	// check if loading state is the same
	if (bst.prematch.loadingState !== null) {
		if (
			bst.prematch.loadingState.startDate === dayInfo.startDate &&
			bst.prematch.loadingState.days === dayInfo.days &&
			bst.prematch.loadingState.idSport === dayInfo.idSport
		) {
			debug('duplicate request. skipping');
			return;
		}
	}

	let eventsLoaded = true;

	debug('eventsDayCache = ', JSON.parse(JSON.stringify(bst.prematch.eventsDayCache)));

	// check events cache
	for (let i = 0; i < days; i++) {
		if (
			bst.prematch.eventsDayCache[
			`${dayInfo.idSport}_${dayInfo.startDate + i * 24 * 3600 * 1000}`
			] !== 1
		) {
			eventsLoaded = false;
			break;
		}
	}

	/*
	if (eventsLoaded) {
		debug("events already loaded. skiping");
		return;
	}
	*/

	// cancel previous request if any
	if (cancelToken !== null) {
		debug('cancel last request group');
		yield cancelToken.cancel('cancel by op');
	}

	// create new cancel token
	//cancelToken = axios.CancelToken.source();

	debug('eventsLoaded', eventsLoaded, 'skipLoadingState', action.data.skipLoadingState);

	if (!eventsLoaded && !action.data.skipLoadingState) {
		debug('setting loading state');
		yield put(prematchSetLoadingState(dayInfo));
	}

	let pe = prematch;
	if (pWorker !== null) {
		pe = pWorker;
	}

	try {
		debug(`load prematch state for sport ${action.data.idSport} on ${startDate} for ${days}, eventsLoaded`, eventsLoaded);

		/*
		const promises = [
			pe.fetchMeta(null, sds, prematchEndTime, excludeMarkets),
			pe.fetchTopOffer(null, sds, prematchEndTime)
		];

		if (!eventsLoaded) {
			promises.push(
				pe.fetchEvents(null, sds, prematchEndTime, action.data.idSport)
			);
		}

		const [meta, topOffer, events] = yield Promise.all(promises);

		debug("meta", meta, "topOffer", topOffer, "events", events);

		//const events = yield fetchEvents(cancelToken.token, sds, prematchEndTime, action.data.idSport);

		if (pWorker !== null) {
			pWorker.postMessage({
				op: "parse_state",
				dayInfo,
				data: {
					meta: meta.data.data,
					topOffer: topOffer.data.data,
					events: events ? events.data.data : null,
					shortProps: nSoftPrematch.shortProps
				}
			});
		} else {
			yield put(
				prematchSetState(
					dayInfo,
					meta.data.data,
					topOffer.data.data,
					events ? events.data.data : null,
					nSoftPrematch.shortProps
				)
			);
		}
		*/

		let idSport = '0';
		if (!eventsLoaded) {
			idSport = '' + action.data.idSport;
		}

		if (pWorker !== null) {
			debug('pe.fetchPrematch', sds, prematchEndTime, idSport);
			const mst = yield pe.fetchPrematch(sds, prematchEndTime, idSport, action.data.skipMeta);
			debug('mst', mst);
			yield put(prematchMergeState(dayInfo, mst));
		} else {
			const mst = yield prematch.fetchPrematch2(sds, prematchEndTime, idSport, action.data.skipMeta);
			yield put(prematchSetState(dayInfo, mst.meta, mst.topOffer, mst.events, mst.shortProps));
		}
	} catch (e) {
		console.log('Error fetching prematch sport by day', e);
		yield put(appSaveErrorLog('', 'Error fetching prematch', JSON.stringify(e)));
		yield put(prematchSetLoadingState(null));
		cancelToken = null;
		yield setTimeout(() => {
			getStore().dispatch(prematchFetchSportByDay(action.data));
		}, 5000);
	} finally {
		if (yield cancelled()) {
			debug('cancelled', action);
		} else {
			debug('request complete');
			cancelToken = null;
		}

		//yield getStore().dispatch(prematchSetLoadingState(null));
	}
}

export default function* watchPrematchSaga() {
	// eslint-disable-next-line
	if (true || window.config.cordova) {
		yield takeEvery(prematchConstants.INITIALIZE, prematchInitializeSagaCordova);
		yield takeEvery(casinoAppConstants.REINITIALIZE, prematchReinitializeSagaCordova);
	} else {
		yield takeEvery(prematchConstants.INITIALIZE, prematchInitializeSagaWeb);
		yield takeEvery(casinoAppConstants.REINITIALIZE, prematchReinitializeSagaWeb);
	}
	yield takeEvery(prematchConstants.CREATE_TICKET_REQUEST, prematchCreateTicketSaga);
	yield takeLatest(betsSlipConstants.PREMATCH_EVAL_TICKET, prematchEvalTicketSaga);
	yield takeEvery(prematchConstants.CONNECT_TICKET_SOCKET, prematchConnectTicketSocketSaga);
	//yield takeEvery(prematchConstants.AUTH_TICKET_SOCKET, prematchAuthTicketSocketSaga);
	yield takeEvery(prematchConstants.DISCONNECT_TICKET_SOCKET, prematchDisconnectTicketSocketSaga);
	yield takeEvery(prematchConstants.FETCH_MATCH, fetchEventSaga);
	yield takeEvery(prematchConstants.FETCH_MATCHES, fetchEventsSaga);
	yield takeEvery(prematchConstants.FETCH_SPORT_BY_DAY, prematchFetchSportByDaySaga);
	yield takeEvery(prematchConstants.FETCH_TOURNAMENT_MATCHES, fetchTournamentEventsSaga);
	yield takeEvery(actionTypes.application.CHANGE_LANGUAGE, changeLanguageInWorker);
}
