export const hardodedJackpotResp: any = {
  element_type: {
    type_id: 'module.jackpotV2Group',
    internal: [
      {
        id: 'key',
        type: 'string',
        value: null,
      },
      {
        id: 'value',
        type: 'string',
        value: null,
      },
      {
        id: 'pathParamKey',
        type: 'string',
        value: null,
      },
    ],
  },
  data: [
    {
      group: 'egt',
      provider: 'egt',
      jackpots: {
        element_type: {
          type_id: 'module.jackpotV2',
          internal: [],
        },
        data: [
          {
            id: 'I',
            name: 'I',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '111115.48',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 93088,
            largest_win_date: 'Oct 28, 2023 1:00:27 AM',
            largest_win_player: 'player',
            last_win_amount: 11590,
            last_win_date: 'May 29, 2024 8:13:31 AM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 62438,
                  date: 'May 24, 2024 9:37:32 AM',
                  player: 'player',
                },
                {
                  amount: 59547,
                  date: 'May 21, 2024 9:31:46 AM',
                  player: 'player',
                },
                {
                  amount: 57328,
                  date: 'May 4, 2024 12:25:17 PM',
                  player: 'player',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 78103,
                  date: 'Feb 23, 2024 12:37:13 PM',
                  player: 'player',
                },
                {
                  amount: 70763,
                  date: 'Jan 1, 2024 5:24:29 PM',
                  player: 'player',
                },
                {
                  amount: 68432,
                  date: 'Jan 31, 2024 12:12:25 PM',
                  player: 'player',
                },
              ],
            },
          },
          {
            id: 'II',
            name: 'II',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '569.37',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 365259,
            largest_win_date: 'Apr 18, 2023 12:15:21 PM',
            largest_win_player: 'player',
            last_win_amount: 75793,
            last_win_date: 'May 29, 2024 6:38:23 AM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 208445,
                  date: 'May 17, 2024 10:43:00 AM',
                  player: 'player',
                },
                {
                  amount: 193493,
                  date: 'May 2, 2024 6:26:41 PM',
                  player: 'player',
                },
                {
                  amount: 178424,
                  date: 'May 14, 2024 5:59:54 PM',
                  player: 'player',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 263217,
                  date: 'Apr 8, 2024 7:35:14 AM',
                  player: 'player',
                },
                {
                  amount: 239484,
                  date: 'Apr 20, 2024 6:29:20 AM',
                  player: 'player',
                },
                {
                  amount: 236495,
                  date: 'Feb 22, 2024 10:51:28 AM',
                  player: 'player',
                },
              ],
            },
          },
          {
            id: 'III',
            name: 'III',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '134733.74',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 36623824,
            largest_win_date: 'May 29, 2024 7:11:28 AM',
            largest_win_player: 'player',
            last_win_amount: 36623824,
            last_win_date: 'May 29, 2024 7:11:28 AM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 36623824,
                  date: 'May 29, 2024 7:11:28 AM',
                  player: 'player',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 36623824,
                  date: 'May 29, 2024 7:11:28 AM',
                  player: 'player',
                },
                {
                  amount: 19970077,
                  date: 'Jan 8, 2024 2:56:56 PM',
                  player: 'player',
                },
                {
                  amount: 19324140,
                  date: 'Feb 8, 2024 10:01:55 PM',
                  player: 'player',
                },
              ],
            },
          },
          {
            id: 'IV',
            name: 'IV',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '1210349.83',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 254145769,
            largest_win_date: 'Dec 4, 2023 10:24:05 AM',
            largest_win_player: 'player',
            last_win_amount: 205449933,
            last_win_date: 'Mar 4, 2024 1:07:25 PM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 205449933,
                  date: 'Mar 4, 2024 1:07:24 PM',
                  player: 'player',
                },
              ],
            },
          },
        ],
      },
      art_bundle: {
        element_type: {
          type_id: 'arb_jackpot',
          internal: [],
        },
        data: {
          background_mobile: 'https://micros-t.b-cdn.net/jackpot-cards-logo-1702007329987.webp',
          background_tablet: 'https://micros-t.b-cdn.net/jp_header_desktop_jp1-1693581517885.jpg',
          background_desktop: 'https://micros-t.b-cdn.net/jp_header_desktop_jp1-1693581517885.jpg',
          text_group_display_name: 'Jackpot Crown',
          text_jackpot_name_0: 'Spades',
          text_jackpot_name_1: 'Hearts',
          text_jackpot_name_2: 'Diamonds',
          text_jackpot_name_3: 'Clubs',
          jackpot_icon_0: '',
          jackpot_icon_1: '',
          jackpot_icon_2: '',
          jackpot_icon_3: '',
        },
      },
    },
    {
      group: 'egt_egypt_quest',
      provider: 'egt',
      jackpots: {
        element_type: {
          type_id: 'module.jackpotV2',
          internal: [],
        },
        data: [
          {
            id: 'I',
            name: 'I',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '33.00',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 57772,
            largest_win_date: 'Nov 28, 2023 2:30:07 AM',
            largest_win_player: 'player',
            last_win_amount: 3553,
            last_win_date: 'May 29, 2024 7:34:37 AM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 18668,
                  date: 'May 21, 2024 3:11:35 PM',
                  player: 'player',
                },
                {
                  amount: 15912,
                  date: 'May 3, 2024 5:25:59 PM',
                  player: 'player',
                },
                {
                  amount: 15738,
                  date: 'May 15, 2024 12:36:14 AM',
                  player: 'player',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 28703,
                  date: 'Feb 1, 2024 10:38:33 PM',
                  player: 'player',
                },
                {
                  amount: 28667,
                  date: 'Jan 20, 2024 3:17:59 PM',
                  player: 'player',
                },
                {
                  amount: 27949,
                  date: 'Jan 23, 2024 10:58:42 AM',
                  player: 'player',
                },
              ],
            },
          },
          {
            id: 'II',
            name: 'II',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '191.86',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 81048,
            largest_win_date: 'Nov 28, 2023 2:57:09 AM',
            largest_win_player: 'player',
            last_win_amount: 20209,
            last_win_date: 'May 29, 2024 7:19:09 AM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 40147,
                  date: 'May 25, 2024 4:04:51 PM',
                  player: 'player',
                },
                {
                  amount: 33549,
                  date: 'May 21, 2024 3:37:18 PM',
                  player: 'player',
                },
                {
                  amount: 33323,
                  date: 'May 26, 2024 9:21:29 PM',
                  player: 'player',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 50509,
                  date: 'Jan 21, 2024 9:52:55 AM',
                  player: 'player',
                },
                {
                  amount: 49628,
                  date: 'Jan 23, 2024 6:49:30 AM',
                  player: 'player',
                },
                {
                  amount: 48615,
                  date: 'Apr 19, 2024 8:48:24 PM',
                  player: 'player',
                },
              ],
            },
          },
          {
            id: 'III',
            name: 'III',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '1269.24',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 253246,
            largest_win_date: 'Nov 3, 2023 11:15:16 PM',
            largest_win_player: 'player',
            last_win_amount: 133523,
            last_win_date: 'May 29, 2024 3:32:30 AM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 185331,
                  date: 'May 25, 2024 3:40:32 AM',
                  player: 'player',
                },
                {
                  amount: 178828,
                  date: 'May 20, 2024 7:29:38 AM',
                  player: 'player',
                },
                {
                  amount: 174319,
                  date: 'May 23, 2024 12:26:42 PM',
                  player: 'player',
                },
              ],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 197110,
                  date: 'Mar 2, 2024 11:46:55 PM',
                  player: 'player',
                },
                {
                  amount: 195408,
                  date: 'Feb 22, 2024 4:51:30 PM',
                  player: 'player',
                },
                {
                  amount: 192616,
                  date: 'Mar 12, 2024 9:27:14 PM',
                  player: 'player',
                },
              ],
            },
          },
          {
            id: 'IV',
            name: 'IV',
            accumulator_states: {
              element_type: {
                type_id: 'module.jackpotV2.accumulator',
                internal: [],
              },
              data: {
                name: 'main',
                value: '12354725.90',
                is_main: true,
                display_type: 'COUNTER',
              },
            },
            state: 'running',
            state_time: 1716971790494,
            currency: 'RON',
            largest_win_amount: 8756638,
            largest_win_date: 'Jun 19, 2023 6:27:15 AM',
            largest_win_player: 'player',
            last_win_amount: 4739275,
            last_win_date: 'Mar 8, 2024 10:23:23 PM',
            last_win_player: 'player',
            top_monthly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [],
            },
            top_yearly_winners: {
              element_type: {
                type_id: 'module.jackpotV2.winner',
                internal: [],
              },
              data: [
                {
                  amount: 6787462,
                  date: 'Feb 10, 2024 11:35:58 PM',
                  player: 'player',
                },
                {
                  amount: 4739275,
                  date: 'Mar 8, 2024 10:23:23 PM',
                  player: 'player',
                },
              ],
            },
          },
        ],
      },
      art_bundle: {
        element_type: {
          type_id: 'arb_jackpot',
          internal: [],
        },
        data: {
          background_mobile: 'https://micros-t.b-cdn.net/egypt-quest-logo-1702007335667.webp',
          background_tablet: 'https://micros-t.b-cdn.net/jackpot-header-7-1692012443901.png',
          background_desktop: 'https://micros-t.b-cdn.net/jackpot-header-7-1692012443901.png',
          text_group_display_name: 'Jackpot Egypt Quest',
          text_jackpot_name_0: 'Platinum',
          text_jackpot_name_1: 'Gold',
          text_jackpot_name_2: 'Silver',
          text_jackpot_name_3: 'Bronze',
          jackpot_icon_0: '',
          jackpot_icon_1: '',
          jackpot_icon_2: '',
          jackpot_icon_3: '',
        },
      },
    },
  ],
};
