import { store } from '../../store';
import evBus from '../../utils/evbus';
import { styleToObj, getText } from '../../components/modules/wheel/utils/index';
import { specialDateToString } from './functions';
import { saveCalendarSelection } from '../../modules/bets/store/actions/calendar';
import { prematchFetchSportByDay } from '../../modules/bets/store/actions/prematch';

interface Props {
  [key: string]: any;
}
interface Context {
  [key: string]: any;
}

interface Returns extends Array<any | boolean | null> {
  /**
   * value
   *
   * @type {any|null}
   */
  0: any | null;
  /** appliedFromContext */
  1: boolean;
}

interface EvaluatePropFunction {
  (props: Props, context?: Context): boolean;
}

const regExp = /<[^>]*>?/gm;

const globalFN = {
  styleToObj,
  specialDateToString,
  slugify: (str: string) => {
    return String(str)
      .normalize('NFKD') // split accented characters into their base characters and diacritical marks
      .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
      .trim() // trim leading or trailing whitespace
      .toLowerCase() // convert to lowercase
      .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
      .replace(/\s+/g, '-') // replace spaces with hyphens
      .replace(/-+/g, '-'); // remove consecutive hyphens
  },
  setPrematchCalendarToAll: (e: any) => {
    //console.log('setPrematchCalendarToAll', e);
    if (e?.target?.dataset?.mType === 'prematch') {
      const idSport = e?.target?.dataset?.idSport;
      if (idSport) {
        store.dispatch(saveCalendarSelection(0));
        store.dispatch(prematchFetchSportByDay({ day: 0, idSport: idSport, noDays: 31 }));
      }
    }
  },
  getTranslatedText: (original: any) => {
    const lng: string = store.getState().application.language ?? 'en';

    let text = original;

    if (typeof text === 'object') {
      if (text['0'] != null || text['2'] != null || text['42'] != null) {
        const lang: { [id: string]: string } = {
          en: '2',
          ro: '42',
        };

        if (text[lang[lng]]) {
          text = text[lang[lng]];
        } else {
          text = text['2'] ?? text['0'] ?? '';
        }
      }
    } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
      try {
        const tmp = JSON.parse(text);
        if (tmp.en != null || tmp.ro !== null) {
          text = getText(tmp, lng, '');
          if (text) text = text.replace(regExp, '');
        }
      } catch (e) {}
    }

    return text ?? '';
  },
};

export const evaluatePropFunction: EvaluatePropFunction = (props, context) => {
  const state = store.getState();
  const propFunction = props?.properties['propFunction'] != null ? props?.properties['propFunction'] : null;

  if (props != null && propFunction != null && propFunction) {
    try {
      const fn = window.Function(`"use strict"; return ${propFunction}`)();

      // this function might change the props values
      fn(props, context, state, evBus, globalFN);
    } catch (err) {
      console.log('evaluatePropFunction: function call', { err, props: JSON.parse(JSON.stringify(props)), context });
    }
    return true;
  }
  return false;
};
