const mergeCfg = (cfg1, cfg2) => {
    for (const k in cfg2) {
        if (k in cfg1) {
            continue;
        }
        cfg1[k] = cfg2[k];
    }
    return cfg1;
};

const digitainDevConfig = () => ({
    wsUrl: 'https://micros-dev1.play-online.com',
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: 'https://micros-dev1.play-online.com/api/digitain-fetcher/v2/public',
    //wsUrl: 'https://micros-prod1.gambling-solutions.ro',
    //wsPath: '/digitain-fetcher/live/ws',
    //apiURL: 'https://micros-prod1.gambling-solutions.ro/digitain-fetcher/public',

    ticketsWsUrl: 'https://micros-dev1.play-online.com',
    ticketsWsPath: '/api/digitain-tickets/v1/ws',
    ticketsUrl: 'https://micros-dev1.play-online.com/api/digitain-tickets/v1/player',
    //betBuilderUrl: 'https://micros-dev1.play-online.com/digitain-tickets/public/bet-builder',
    betBuilderUrl: 'https://micros-dev1.play-online.com/api/digitain-tickets/v1/public/bet-builder',
    searchUrl: 'https://micros-dev1.play-online.com/api/digitain-fetcher/v2/public/search',

    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=039728cb-e16d-42c5-8597-9cd091f6ccd5&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=039728cb-e16d-42c5-8597-9cd091f6ccd5&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=039728cb-e16d-42c5-8597-9cd091f6ccd5&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    //widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    //widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    //widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: 'https://micros-dev1.play-online.com/api/stats/v1/public/sports/event',
    leagueStatsUrl: 'https://micros-dev1.play-online.com/api/stats/v1/public/sports/euro-2024/team',
});

const digitainMicrosConfig = () => ({
    wsUrl: 'https://micros-prod1.gambling-solutions.ro',
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: 'https://micros-prod1.gambling-solutions.ro/api/digitain-fetcher/v2/public',

    ticketsWsUrl: 'https://micros-prod1.gambling-solutions.ro',
    ticketsWsPath: '/api/digitain-tickets/v1/ws',
    ticketsUrl: 'https://micros-prod1.gambling-solutions.ro/api/digitain-tickets/v1/player',
    betBuilderUrl: 'https://micros-prod1.gambling-solutions.ro/api/digitain-tickets/v1/public/bet-builder',
    searchUrl: 'https://micros-prod1.gambling-solutions.ro/api/digitain-fetcher/v2/public/search',

    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: 'https://micros-prod1.gambling-solutions.ro/api/stats/v1/public/sports/event',
    leagueStatsUrl: 'https://micros-prod1.gambling-solutions.ro/api/stats/v1/public/sports/euro-2024/team',
});

const digitainWinnerFunDevConfig = () => ({
    wsUrl: 'https://micros-dev1.play-online.com',
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: 'https://micros-dev1.play-online.com/api/digitain-fetcher/v2/public',

    ticketsWsUrl: 'https://micros-dev1.play-online.com',
    ticketsWsPath: '/api/digitain-tickets-wf/v1/ws',
    ticketsUrl: 'https://micros-dev1.play-online.com/api/digitain-tickets-wf/v1/player',
    searchUrl: 'https://micros-dev1.play-online.com/api/digitain-fetcher/v2/public/search',

    widgetUrl: 'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl: 'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl: 'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',
    betBuilderUrl: 'https://micros-dev1.play-online.com/digitain-tickets-wf/public/bet-builder',

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: 'https://micros-dev1.play-online.com/api/stats/v1/public/sports/event',
    leagueStatsUrl: 'https://micros-dev1.play-online.com/api/stats/v1/public/sports/euro-2024/team',
});

const digitainWinnerFunMicrosConfig = () => ({
    wsUrl: 'https://micros-prod1.gambling-solutions.ro',
    wsPath: '/api/digitain-fetcher/v2/live/ws',
    apiURL: 'https://micros-prod1.gambling-solutions.ro/api/digitain-fetcher/v2/public',

    ticketsWsUrl: 'https://micros-prod1.gambling-solutions.ro',
    ticketsWsPath: '/api/digitain-tickets-wf/v1/ws',
    ticketsUrl: 'https://micros-prod1.gambling-solutions.ro/api/digitain-tickets-wf/v1/player',
    betBuilderUrl: 'https://micros-prod1.gambling-solutions.ro/api/digitain-tickets-wf/v1/public/bet-builder',
    searchUrl: 'https://micros-prod1.gambling-solutions.ro/api/digitain-fetcher/v2/public/search',

    widgetUrl:
        'https://widgets.feeddigi.com/api/v1/liveinfo/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]&IsSmall=true',
    widgetLiveTvUrl:
        'https://widgets.feeddigi.com/api/v1/livetv/[MATCHID]?pId=4a8a4633-1e72-4274-aefe-ff53396605b0&t=%23FFF&t2=%23FFF&b=%230e5bb3&l=[LANGUAGE]',
    widgetCheckUrl:
        'https://sp-spc-api.sportdigi.com/api/v1/b2c/ScoutProvider/GetScoutData?partnerId=4a8a4633-1e72-4274-aefe-ff53396605b0&responseTypeId=1&langIsoCode=en&ids=[MATCHID]',

    defaultSportId: '1',
    marketCountNsoft: 3,

    statsUrl: 'https://micros-prod1.gambling-solutions.ro/api/stats/v1/public/sports/event',
    leagueStatsUrl: 'https://micros-prod1.gambling-solutions.ro/api/stats/v1/public/sports/euro-2024/team',
});

// we need to do it like this because worker doesn't have access to window object
const getGlobalConfig = () => {
    try {
        if (typeof window !== 'undefined' && window.config) {
            return window.config;
        }
    } catch (e) {
    }

    try {
        if (globalThis?.global?.config) {
            return globalThis.global.config;
        }
    } catch (e) {
    }

    return {};
};

let digitainConfig = () => {
    let config = getGlobalConfig();

    if (config.environment === 'staging') {
        return mergeCfg(digitainDevConfig(), digitainMicrosConfig());
    }
    return digitainMicrosConfig();
};

let digitainWinnerFunConfig = () => {
    let config = getGlobalConfig();

    if (config.environment === 'staging') {
        return mergeCfg(digitainWinnerFunDevConfig(), digitainWinnerFunMicrosConfig());
    }
    return digitainWinnerFunMicrosConfig();
};

export { digitainConfig, digitainWinnerFunConfig };
