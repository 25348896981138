import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { onBetToggle } from './onBetToggle';

const ContainerDiv = styled.div((props) => props.$styleText);

export const Container = (componentProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  if (!isVisible) return null;

  let onClick = null;
  if (props.properties && props.properties.onClick && typeof props.properties.onClick === 'function') {
    onClick = props.properties.onClick;
  } else if (
    componentProps.properties &&
    componentProps.properties.onClick &&
    typeof componentProps.properties.onClick === 'function'
  ) {
    onClick = componentProps.properties.onClick;
  }

  if (props?.properties?.betHolder) {
    // special case; onClick handler for all of our odds
    onClick = onBetToggle;
  }

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props?.properties?.key) {
    extraDataProps['key'] = props?.properties?.key;
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }

  return (
    <ContainerDiv
      className={props.className ?? ''}
      $styleText={props.styleText}
      onClick={onClick}
      style={props.style}
      id={props?.properties?.id}
      {...extraDataProps}
    >
      {componentProps.children}
    </ContainerDiv>
  );
};
