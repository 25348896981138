import * as React from 'react';

import './page.scss';
import { useAppSelector, useAppDispatch } from '../../store';
import { fetchElements } from '../../store/slices/dataElements';
import { fetchSources } from '../../store/slices/dataSources';
import craftJsParser from '../utils/craftJsParser';
import PageLoader from '../page-loader';
import PageMetaTags from '../page-meta-tags';
import parseDataSources from '../utils/parseDataSources';

type PageProps = {
  id: string;
  tagProps?: {
    [key: string]: any;
  };
};

const Page: React.FC<PageProps> = (props) => {
  const { id } = props;

  //console.log('Page[props]', props);

  const config = useAppSelector((state) => state.templatesConfig);
  const authenticated = useAppSelector(
    (state) => (state.authentication && state.authentication.auth_type === 'user') as boolean,
  );
  const isAuthInProgress = useAppSelector((state) => state.authentication.in_progress);
  const authenticationToken = useAppSelector((state) => state.authentication.access_token);
  const dispatch = useAppDispatch();
  const dataSourcesLoaded = useAppSelector((state) => state.dataSources.loaded);

  const [doneRender, setDoneRender] = React.useState<any>(null);

  const page = config.pages[id];
  let projectData = page.projectData;

  if (page && page.a && !authenticated && config.setPages && config.setPages['/login']) {
    if (config.setPages['/login'].page_layout_id) {
      projectData = config.pageLayouts[config.setPages['/login'].page_layout_id].projectData;
    } else {
      projectData = config.pages[config.setPages['/login'].page_id].projectData;
    }
  }

  React.useEffect(() => {
    if (authenticationToken) {
      const [dataSources, dataElements] = parseDataSources({
        craftState: projectData,
      });
      //console.log('dataSources', dataSources, dataElements);
      if (dataSources.length) {
        // @ts-ignore
        dispatch(fetchSources({ ids: dataSources }));
      }
      if (dataElements.length) {
        // @ts-ignore
        dispatch(fetchElements({ ids: dataElements }));
      }

      if (dataSources.length && dataElements.length) {
        setDoneRender(1);
      } else {
        setDoneRender(2);
      }
    }
  }, [projectData, authenticationToken]);

  React.useEffect(() => {
    if (doneRender === 2) {
      try {
        window.setTimeout(() => {
          // @ts-ignore
          if (typeof window.sendDrEvent === 'function') {
            // @ts-ignore
            window.sendDrEvent({ type: 'render', detail: `page id ${id}` });
          }
        }, 10);
      } catch (e) {}
    }
  }, [doneRender]);

  React.useEffect(() => {
    if (doneRender === 1 && dataSourcesLoaded) {
      setDoneRender(2);
    }
  }, [doneRender, dataSourcesLoaded]);

  if (page.a && !authenticated) {
    if (isAuthInProgress) {
      return <PageLoader />;
    }

    if (config.setPages && config.setPages['/login']) {
      const content = craftJsParser({
        craftState: projectData,
        rootNodeId: 'ROOT',
        pageLayoutId: config.setPages['/login'].page_layout_id,
        pageId: config.setPages['/login'].page_id,
        pageType: 'page',
        options: {
          authenticated,
        },
        withProvider: true,
      });

      return <React.Fragment>{content}</React.Fragment>;
    }
  }

  const content = craftJsParser({
    craftState: projectData,
    rootNodeId: 'ROOT',
    pageId: id,
    pageType: 'page',
    options: {
      authenticated,
    },
    withProvider: true,
  });

  let meta = null;
  if (page.pmd && Object.keys(page.pmd).length) {
    meta = <PageMetaTags meta={page.pmd} />;
  } else {
    // just in case this were set by a previous page with metadata but this current page doesn't have metadata
    document.documentElement.classList.remove('ligh-mode', 'dark-mode');
  }

  return (
    <React.Fragment>
      {meta}
      {content}
    </React.Fragment>
  );
};

export default Page;
