import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { getText } from '@/components/modules/wheel/utils';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const TextComponent = styled.div((props) => props.$styleText);

export const Text = (componentProps) => {
  let props = componentProps;

  const { i18n, t } = useTranslation();
  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  if (!isVisible) return null;

  let text = props.properties.text;

  if (typeof text === 'object') {
    if (text['0'] != null || text['2'] != null || text['42'] != null) {
      const lang = {
        en: '2',
        ro: '42',
      };

      if (text[lang[i18n.language]]) {
        text = text[lang[i18n.language]];
      } else {
        text = text['2'] ?? text['0'] ?? '';
      }
    } else if (text.en != null || text.ro !== null) {
      text = getText(text, i18n.language, '');
    }
  } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
    try {
      const tmp = JSON.parse(text);
      if (tmp.en != null || tmp.ro !== null) {
        text = getText(tmp, i18n.language, '');
      }
    } catch (e) { }
  }

  text = text != null ? t(text, props.properties.placeholders ? props.properties.placeholders : {}) : '';

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }

  //console.log('Text[props]', text);

  return (
    <TextComponent
      className={props.className ?? ''}
      $styleText={props.styleText}
      style={props.style}
      dangerouslySetInnerHTML={{ __html: text }}
      data-node-id={props.nodeId}
      onClick={props?.properties?.onClick ?? null}
      {...extraDataProps}
    />
  );
};

export default React.memo(Text, equalWithoutChildren);
