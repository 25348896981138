import { put } from 'redux-saga/effects';
import * as actions from './../actions';
import ClientAPI from './../../ClientAPI/ClientAPI';
import * as paths from './../../ClientAPI/paths';

import { receivedWallet } from '../../../../store/slices/multiCurrencyWallet';

export function* requestWalletSaga(action) {
  const axios = ClientAPI.getInstance();

  const currentCurrency = ClientAPI.getStore().getState().currencies.currentCurrency;

  try {
    const response = yield axios({
      url: paths.wallet.GET,
      method: 'post',
      data: {
        responseType: 'wallet-preview',
        // currencyCode: currentCurrency,
      },
    });

    if (!response) {
      throw new Error('[ERROR] Wallet response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Wallet response has no 'result' property");
    }

    yield put(actions.walletReceived(response.result, currentCurrency));
    yield put(receivedWallet({ wallet: response.result }));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* authRequestWalletSaga(action) {
  if (typeof action['authType'] === 'undefined') {
    return;
  }

  if (!['user', 'token'].includes(action.authType)) {
    return;
  }

  yield put(actions.requestWallet());
  yield put(actions.requestPendingWithdrawals());
}

export function* requestRemoveBonusSaga(action) {
  const axios = ClientAPI.getInstance();
  const bonusID = action.bonusID;

  try {
    const response = yield axios({
      url: paths.wallet.REQUEST_REMOVE_BONUS,
      method: 'post',
      data: {
        bonusId: [bonusID],
      },
    });

    if (!response) {
      throw new Error('[ERROR] Request Remove Bonus response is empty!');
    }

    if (typeof response['result'] === 'undefined') {
      throw new Error("[ERROR] Request Remove Bonus response has no 'result' property");
    }

    yield put(actions.receivedRemoveBonus(response.result));
  } catch (error) {
    console.log(error);
    return;
  }
}

export function* receivedRemovedBonusSaga() {
  yield put(actions.resetWallet());
  yield put(actions.requestWallet());
}
