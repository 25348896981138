// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { endpoints } from '../../api/endpoints';
import StyleIds from '../../constants/css-ids';

const DEBUG = true;

type FetchConfigProps = {
  tenantId: string;
  siteId: string;
};

type FetchConfigResult = {
  data: {
    page_layouts: any;
    set_pages: any;
    set_css_variables: any;
    set_css_light_variables: any;
    set_css_dark_variables: any;
    set_components_defaults: any;
    set_components_data_defaults: any;
    components_data: any;
    components_data_ref: any;
    components: any;
    components_elements: any;
    data_sources: any;
    data_elements: any;
    data_element_types: any;
    break_points: any[];
    common_css: string;
    pages: any;
    fonts: string;
    theme_mode: string;
    popups: any;
  };
  success: boolean;
};

type FetchConfigError = {
  rejectValue: {
    error: string;
  };
};

export const fetchConfig = createAsyncThunk<FetchConfigResult, FetchConfigProps, FetchConfigError>(
  'templatesConfig/list',
  async ({ tenantId, siteId }, { rejectWithValue }) => {
    DEBUG && console.time('fetchConfig.query');
    try {
      const response = await axios.get(endpoints.templatization.config, {
        params: {
          tenantId,
          siteId,
          path: location.pathname + location.search,
        },
      });

      if (response.data) {
        return { data: response.data, success: true };
      }

      return rejectWithValue({
        error: "Couldn't fetch config",
      });
    } catch (err: any) {
      const errResp = { error: err.toString() };
      return rejectWithValue(errResp);
    }
  },
);

interface SetCSSVariables {
  [id: string]: string;
}

interface PageLayouts {
  [id: string]: {
    projectData: string;
  };
}

interface SetPages {
  [url: string]: {
    page_id: string;
    page_layout_id: string;
  };
}

interface Pages {
  [id: string]: {
    projectData: any;
    a: boolean; // if true then requires user to be authenticated
    pmd: {
      [id: string]: string;
    } | null; // page meta data if any
  };
}

interface Popups {
  [id: string]: {
    projectData: any;
    a: boolean; // if true then requires user to be authenticated
  };
}

interface DataSources {
  [id: string]: {
    id: string;
    name: string;
    items: any[];
  };
}

interface DataElements {
  [id: string]: {
    id: string;
    name: string;
    data: any[];
  };
}

interface Internal {
  id: string;
  type: string;
  value: any;
}

interface DataElementTypes {
  [id: string]: {
    id: string;
    description: string;
    properties: any[];
    internal: Internal[];
    type_id: string;
    tags: string[];
  };
}

interface ComponentsData {
  [id: string]: {
    [key: string]: string | number | boolean | null;
  };
}

interface ComponentsDataRef {
  [id: string]: string;
}

interface Components {
  [id: string]: any;
}
interface BreakPoints {
  name: string;
  value: string;
}
export interface ConfigReducer {
  setComponentsDefaults: any;
  setComponentsDataDefaults: any;
  setCSSVariables: SetCSSVariables;
  setCSSLightVariables: SetCSSVariables;
  setCSSDarkVariables: SetCSSVariables;
  setPages: SetPages;
  pageLayouts: PageLayouts;
  pages: Pages;
  popups: Popups;
  fonts: string;
  themeMode: string;
  dataSources: DataSources;
  dataElements: DataElements;
  dataElementTypes: DataElementTypes;
  components: Components;
  componentsElements: Components;
  componentsData: ComponentsData;
  componentsDataRef: ComponentsDataRef;
  breakPoints: BreakPoints[];
  commonCSS: string;
  inProgress: boolean;
  loaded: boolean;
  error: any;
  version: number;
}

const setWebsiteStyles = (state: any) => {
  if (Object.keys(state.setCSSVariables).length !== 0 || Object.keys(state.setCSSDarkVariables).length !== 0) {
    let styleTag = document.getElementById(StyleIds.styleRootId);

    if (!styleTag) {
      styleTag = document.createElement('style');
      styleTag.id = StyleIds.styleRootId;
      document.head.append(styleTag);
    }

    if (styleTag) {
      let styleTxt = '';
      if (state.fonts) {
        styleTxt += `${state.fonts}\n\n`;
      }
      const commonCss = true;
      if (commonCss) {
        const entries = Object.entries(state.setCSSVariables);

        entries.sort((a: any, b: any) => {
          if (a[1].indexOf('var(') !== -1 && b[1].indexOf('var(') !== -1) {
            return a[0].localeCompare(b[0]);
          }
          if (a[1].indexOf('var(') !== -1) return 1;
          if (b[1].indexOf('var(') !== -1) return -1;
          return a[0].localeCompare(b[0]);
        });

        if (entries.length) {
          const breakPointVars: any = {
            default: [],
          };

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              breakPointVars.default = [];
            } else {
              breakPointVars[bp.value] = [];
            }
          });

          const re = RegExp('^(.*?)\\[(.*?)\\]$');

          entries.forEach(([key, value]) => {
            const matches = re.exec(key);
            if (matches) {
              if (breakPointVars[matches[2]] != null) {
                breakPointVars[matches[2]].push(`--${matches[1]}: ${value};`);
              }
              return;
            }

            breakPointVars.default.push(`--${key}: ${value};`);
          });

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              styleTxt += `:root {\n${breakPointVars.default.join('\n')}\n}\n`;
            } else {
              if (breakPointVars[bp.value] && breakPointVars[bp.value].length) {
                styleTxt += `@media only screen and (min-width: ${bp.value}) {\n:root {\n${breakPointVars[
                  bp.value
                ].join('\n')} \n}\n}\n`;
              }
            }
          });
        }
      }

      let themeModes = [];

      if (state.themeMode === 'dark') {
        themeModes = ['dark-mode', 'light-mode'];
      } else {
        themeModes = ['light-mode', 'dark-mode'];
      }

      themeModes.forEach((themeMode, index) => {
        const entries = Object.entries(
          themeMode === 'dark-mode' ? state.setCSSDarkVariables : state.setCSSLightVariables,
        );

        entries.sort((a: any, b: any) => {
          if (a[1].indexOf('var(') !== -1 && b[1].indexOf('var(') !== -1) {
            return a[0].localeCompare(b[0]);
          }
          if (a[1].indexOf('var(') !== -1) return 1;
          if (b[1].indexOf('var(') !== -1) return -1;
          return a[0].localeCompare(b[0]);
        });

        if (entries.length) {
          const breakPointVars: any = {
            default: [],
          };

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              breakPointVars.default = [];
            } else {
              breakPointVars[bp.value] = [];
            }
          });

          const re = RegExp('^(.*?)\\[(.*?)\\]$');

          entries.forEach(([key, value]) => {
            const matches = re.exec(key);
            if (matches) {
              if (breakPointVars[matches[2]] != null) {
                breakPointVars[matches[2]].push(`--${matches[1]}: ${value};`);
              }
              return;
            }

            breakPointVars.default.push(`--${key}: ${value};`);
          });

          if (index === 0) {
            state.breakPoints.forEach((bp: any, index: number) => {
              if (index === 0) {
                styleTxt += `:root {\n${breakPointVars.default.join('\n')}\n}\n`;
              } else {
                if (breakPointVars[bp.value] && breakPointVars[bp.value].length) {
                  styleTxt += `@media only screen and (min-width: ${bp.value}) {\n:root {\n${breakPointVars[
                    bp.value
                  ].join('\n')} \n}\n}\n`;
                }
              }
            });
          }

          state.breakPoints.forEach((bp: any, index: number) => {
            if (index === 0) {
              styleTxt += `.${themeMode} {\n${breakPointVars.default.join('\n')}\n}\n`;
            } else {
              if (breakPointVars[bp.value] && breakPointVars[bp.value].length) {
                styleTxt += `@media only screen and (min-width: ${bp.value}) {\n.${themeMode} {\n${breakPointVars[
                  bp.value
                ].join('\n')} \n}\n}\n`;
              }
            }
          });
        }
      });

      styleTxt += state.commonCSS ? `\n\n${state.commonCSS}` : '';

      styleTag.textContent = styleTxt;
    }
  }
};

export const templatesConfigSlice = createSlice({
  name: 'templatesConfig',
  initialState: <ConfigReducer>{
    setComponentsDefaults: {},
    setComponentsDataDefaults: {},
    setCSSVariables: {},
    setCSSLightVariables: {},
    setCSSDarkVariables: {},
    setPages: {},
    pageLayouts: {},
    componentsData: {},
    componentsDataRef: {},
    dataSources: {},
    components: {},
    componentsElements: {},
    pages: {},
    popups: {},
    fonts: '',
    themeMode: 'light',
    breakPoints: [] as BreakPoints[],
    commonCSS: '',
    inProgress: false,
    loaded: false,
    error: null,
    version: 0,
  },
  reducers: {
    reset: (_state) => {
      /* example */
    },
    updatePageContent: (state, action) => {
      if (state.pages[action.payload.id] != null && state.pages[action.payload.id].projectData) {
        state.pages[action.payload.id].projectData = action.payload.content;
      }
    },
    updateComponentDataDefaults: (state, action) => {
      state.setComponentsDataDefaults = action.payload.content;
    },
    updateComponentDefaults: (state, action) => {
      state.setComponentsDefaults = action.payload.content;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConfig.fulfilled, (state, action) => {
        state.inProgress = false;
        state.loaded = true;
        if (action.payload.success) {
          if (typeof action.payload.data['page_layouts'] !== 'undefined')
            state.pageLayouts = action.payload.data['page_layouts'];
          if (typeof action.payload.data['set_pages'] !== 'undefined')
            state.setPages = action.payload.data['set_pages'];
          if (typeof action.payload.data['set_components_defaults'] !== 'undefined')
            state.setComponentsDefaults = action.payload.data['set_components_defaults'];
          if (typeof action.payload.data['set_components_data_defaults'] !== 'undefined')
            state.setComponentsDataDefaults = action.payload.data['set_components_data_defaults'];
          if (typeof action.payload.data['components_data'] !== 'undefined')
            state.componentsData = action.payload.data['components_data'];
          if (typeof action.payload.data['components_data_ref'] !== 'undefined')
            state.componentsDataRef = action.payload.data['components_data_ref'];
          if (typeof action.payload.data['pages'] !== 'undefined') state.pages = action.payload.data['pages'];
          if (typeof action.payload.data['components'] !== 'undefined')
            state.components = action.payload.data['components'];
          if (typeof action.payload.data['data_sources'] !== 'undefined')
            state.dataSources = action.payload.data['data_sources'];
          if (typeof action.payload.data['data_elements'] !== 'undefined')
            state.dataElements = action.payload.data['data_elements'];
          if (typeof action.payload.data['data_element_types'] !== 'undefined')
            state.dataElementTypes = action.payload.data['data_element_types'];

          if (typeof action.payload.data['theme_mode'] !== 'undefined')
            state.themeMode = action.payload.data['theme_mode'];
          if (typeof action.payload.data['components_elements'] !== 'undefined')
            state.componentsElements = action.payload.data['components_elements'];
          if (typeof action.payload.data['popups'] !== 'undefined') state.popups = action.payload.data['popups'];

          // Let's not store any of the CSS stuff in the state
          // if (typeof action.payload.data['set_css_variables'] !== 'undefined') state.setCSSVariables = action.payload.data['set_css_variables'];
          // if (typeof action.payload.data['set_css_light_variables'] !== 'undefined') state.setCSSLightVariables = action.payload.data['set_css_light_variables'];
          // if (typeof action.payload.data['set_css_dark_variables'] !== 'undefined') state.setCSSDarkVariables = action.payload.data['set_css_dark_variables'];
          // if (typeof action.payload.data['fonts'] !== 'undefined') state.fonts = action.payload.data['fonts'];
          // if (typeof action.payload.data['break_points'] !== 'undefined') state.breakPoints = action.payload.data['break_points'];
          // if (typeof action.payload.data['common_css'] !== 'undefined') state.commonCSS = action.payload.data['common_css'];

          setWebsiteStyles({
            setCSSVariables: action.payload.data['set_css_variables'],
            setCSSLightVariables: action.payload.data['set_css_light_variables'],
            setCSSDarkVariables: action.payload.data['set_css_dark_variables'],
            fonts: action.payload.data['fonts'],
            breakPoints: action.payload.data['break_points'],
            commonCSS: action.payload.data['common_css'],
          });
        }

        state.version = state.version + 1;
        DEBUG && console.timeEnd('fetchConfig.query');
        //DEBUG && console.log('fetchConfig.fulfilled', action.payload);
      })
      .addCase(fetchConfig.pending, (state) => {
        state.inProgress = true;
      })
      .addCase(fetchConfig.rejected, (state, action) => {
        state.inProgress = false;
        state.loaded = false;
        state.error = action.payload?.error;
        DEBUG && console.log('fetchConfig.rejected', action.payload);
      });
  },
});

export const { reset, updatePageContent, updateComponentDataDefaults, updateComponentDefaults } =
  templatesConfigSlice.actions;

export default templatesConfigSlice.reducer;
