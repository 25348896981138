import React, { useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, Link as NavigateLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getText } from '@/components/modules/wheel/utils';

import { handleActions } from '../../components/action-handler/actions';
import { DataElementContext } from '../common/DataElementContext';
import { equalWithoutChildren } from '../../utils/fastDeepEqual';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const LinkComponent = styled.a((props) => props.$styleText);
const NavigateLinkComponent = styled(NavigateLink)((props) => props.$styleText);

export const Link = (componentProps) => {
  let props = componentProps;

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);
  if (!isVisible) return null;

  let { href, target, text, actionType, eventName } = props.properties;

  if (typeof text === 'object') {
    if (text['0'] != null || text['2'] != null || text['42'] != null) {
      const lang = {
        en: '2',
        ro: '42',
      };

      if (text[lang[i18n.language]]) {
        text = text[lang[i18n.language]];
      } else {
        text = text['2'] ?? text['0'] ?? '';
      }
    } else if (text.en != null || text.ro !== null) {
      text = getText(text, i18n.language, '');
    }
  } else if (text?.[0] === '{' && text?.[text.length - 1] === '}') {
    try {
      const tmp = JSON.parse(text);
      if (tmp.en != null || tmp.ro !== null) {
        text = getText(tmp, i18n.language, '');
      }
    } catch (e) { }
  }

  text = text != null ? t(text, props.properties.placeholders ? props.properties.placeholders : {}) : '';

  const handleClick = (e) => {
    if (actionType === 'event' && eventName) {
      handleActions(eventName, props, navigate, dataElementContext, e);
      return;
    }

    if (target === '_self' && href.indexOf('/') === 0) {
      navigate(href);
    }
  };

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      } else if (key === 'ariaLabel' || key === 'preventScrollReset') {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  if (actionType === 'react-router' || (actionType === 'event' && eventName === 'show-match-details')) {
    return (
      <NavigateLinkComponent
        to={href}
        className={props.className ?? ''}
        $styleText={props.styleText}
        style={props.style}
        dangerouslySetInnerHTML={{ __html: text }}
        onClick={props.properties.onClick}
        {...extraDataProps}
      />
    );
  }

  if (props?.properties?.title) {
    extraDataProps['title'] = props?.properties?.title;
  }

  return (
    <LinkComponent
      className={props.className ?? ''}
      href={href}
      target={target !== '_self' ? target : null}
      $styleText={props.styleText}
      style={props.style}
      onClick={props.properties.onClick ?? handleClick}
      dangerouslySetInnerHTML={{ __html: text }}
      {...extraDataProps}
    />
  );
};

export default React.memo(Link, equalWithoutChildren);
