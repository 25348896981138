import axios from 'axios';
import moment from 'moment';
//import openSocket from "socket.io-client";
import { debug } from '../../../utils';
import * as nsoft from '../../../utils/nsoft';

import { digitainConfig } from '../../../api/config/digitain';

let defaultLang = 'ro';
const getLanguage = () => defaultLang;
export const setLanguage = (lang) => {
  defaultLang = lang;
};

export const fetchMeta = async (cancelToken, now, prematchEndTime, excludeMarkets) => {
  //console.log("fetchMeta " + getLanguage());

  const dCfg = digitainConfig();

  const params = {
    timeFrom: now,
    timeTo: prematchEndTime,
    exclude: 'marketOfferTemplates',
  };

  if (excludeMarkets) {
    params['exclude'] = 'markets,marketGroups,marketOfferTemplates';
  }

  return axios.post(dCfg.apiURL + '/meta', params, {
    cancelToken,
  });
};

export const fetchTopOffer = (cancelToken, now, prematchEndTime) => ({ data: { data: null } });
// axios.get(nSoftPrematch.apiURL + "/api/v1/topOffer", {
// 	params: {
// 		"filter[from]": now,
// 		"filter[to]": prematchEndTime,
// 		timezone: "Europe/Bucharest",
// 		language: {
// 			default: getLanguage(),
// 			category: getLanguage(),
// 			sport: getLanguage(),
// 			tournament: getLanguage(),
// 			market: getLanguage(),
// 			marketGroup: getLanguage()
// 		},
// 		companyUuid: nSoftPrematch.companyUuid,
// 		deliveryPlatformId: 3,
// 		sort: "position",
// 		topOffer: {
// 			matches: true
// 		}
// 	},
// 	cancelToken
// });

export const fetchEvents = async (cancelToken, now, prematchEndTime, sportId) => {
  const dCfg = digitainConfig();

  sportId = sportId || dCfg.defaultSportId;

  const params = {
    timeFrom: now,
    timeTo: prematchEndTime,
    sportId: sportId,
  };

  if (dCfg.marketCountNsoft && (typeof window !== 'undefined' && window.config && window.config.nsoftEnabled === '1')) {
    params['marketCount'] = dCfg.marketCountNsoft;
  }

  return axios
    .post(dCfg.apiURL + '/events', params, {
      cancelToken,
    })
    .then(r => {
      //console.log("r", r);

      if (r.data && r.data.data && r.data.data.events) {
        const rd = r.data.data;

        const std = moment(now).utc().valueOf();
        const etd = moment(prematchEndTime).utc().valueOf();

        let errors = 0;

        let ne = rd.events;
        // const ne = rd.events.filter(e => {
        //   const sd = nsoft.stdMatchStartTime(e, null, 'matchDateTime');

        //   if (std <= sd && sd <= etd) {
        //     //console.log(sd);
        //   } else {
        //     //debug(std, etd, sd, moment(sd).format());
        //     errors++;
        //   }

        //   return std <= sd && sd <= etd;
        // });

        if (errors > 0) {
          debug(
            'std',
            now,
            'etd',
            prematchEndTime,
            'total',
            rd.events.length,
            'outside',
            errors,
            'inside',
            rd.events.length - errors
          );
        }

        r.data.data.events = ne;
      }

      return r;
    });
};

export const fetchEventsById = async (cancelToken, ids, mType) => {
  const dCfg = digitainConfig();

  const params = {
    ids,
  };

  if (mType) {
    params['mtype'] = mType;
  }

  //if (dCfg.marketCountNsoft && (typeof window !== 'undefined' && window.config && window.config.nsoftEnabled === '1')) {
  //  params['marketCount'] = dCfg.marketCountNsoft;
  //}

  return axios.post(dCfg.apiURL + '/events', params, {
    cancelToken,
  });
};

let prematchSocket = null;
let mergeInterval = 0;
let tempMatches = {};

const connect = async cbMergeMatches => {
  const ret = true;
  if (ret) return;

  if (prematchSocket !== null) {
    //console.log("prematch socket already connected");
    return;
  }

  await cbMergeMatches(null);

  mergeInterval = setInterval(async () => {
    if (Object.keys(tempMatches).length > 0) {
      await cbMergeMatches(tempMatches);
      tempMatches = {};
    }
  }, 10000);

  let socket;

  // const socket = openSocket(nSoftPrematch.wsURL, {
  // 	forceNew: true,
  // 	transports: ["websocket"],
  // 	query: {
  // 		company: nSoftPrematch.companyUuid
  // 	}
  // });

  /*
  var onevent = socket.onevent;
  socket.onevent = function (packet) {
    var args = packet.data || [];
    onevent.call(this, packet); // original call
    packet.data = ["*"].concat(args);
    onevent.call(this, packet); // additional call to catch-all
  };

  socket.on("*", (...data) => {
    console.log("socket data", ...data);
  });
  */

  //console.log("socket", socket);

  /*
  let keepAliveInterval;
  const keepAlive = () => {
    socket.emit("message", {
      type: "serviceAlive"
    });
  };
  */

  const subscribe = () => {
    //console.log("Subscribing...");

    socket.emit('subscribe', {
      language: {
        default: getLanguage(),
        markets: getLanguage(),
        outcomes: getLanguage(),
        events: getLanguage(),
      },
      //betshopUuid: "7f4c7b8b-745f-47e6-b564-90ab15897d40",
      //deviceUuid: "e97d662d-39f1-4dd6-8ec9-83791344c170"
    });

    /*
    clearInterval(keepAliveInterval);
    keepAliveInterval = setInterval(keepAlive, 10000);
    */
  };

  socket.on('connect', () => {
    //console.log("Connected");

    subscribe();
  });

  socket.on('event', function (data) {
    //console.log("Socket event data", data);
  });

  socket.on('error', error => {
    //console.log("Socket error", error);
  });

  socket.on('connect_error', error => {
    //console.log("Socket connect error", error);
  });

  socket.on('message', message => {
    //console.log("message", message.type, message.data);
  });

  socket.on('addEvent', data => {
    //console.log("prematch add event: ", data);
    //getStore().dispatch(prematchAddMatches(data));
    if (data.data !== null) {
      data.data['fromWs'] = true;
      tempMatches[data.data.id] = data.data;
    }
  });

  prematchSocket = socket;

};

export const initialize = async (cbMergeMatches, onlyMeta) => {
  debug('worker initialize prematch');

  const dCfg = digitainConfig();

  const now = moment();
  const snow = now.format('YYYY-MM-DDTHH:mm:ss');

  const prematchEndDay = moment().add(1, 'day').startOf('day').subtract(1, 'second').format('YYYY-MM-DDTHH:mm:ss');
  const prematchEndMonth = moment().add(1, 'month').startOf('day').subtract(1, 'second').format('YYYY-MM-DDTHH:mm:ss');

  const dayInfo = {
    startDate: now.startOf('day').valueOf(),
    idSport: dCfg.defaultSportId,
    days: 1,
  };

  try {
    debug('load prematch state');

    //const prems = [fetchMeta(null, snow, prematchEndMonth)];
    const prems = [fetchMeta(null, snow, prematchEndDay)];

    if (!onlyMeta) {
      prems.push(fetchTopOffer(null, snow, prematchEndDay));
      prems.push(fetchEvents(null, snow, prematchEndDay, dCfg.defaultSportId));
    }

    const [meta, topOffer, events] = await Promise.all(prems);

    debug('connect prematch socket');
    connect(cbMergeMatches);

    return [
      onlyMeta ? null : dayInfo,
      meta.data.data,
      topOffer ? topOffer.data.data : null,
      events ? events.data.data : null,
      dCfg.shortProps,
    ];
  } catch (e) {
    console.log('Error fetching prematch ', e);
  }
};

let state = null;

export const fetchPrematch = async (start, end, idSport) => {
  const prems = [fetchMeta(null, start, end, true), fetchTopOffer(null, start, end)];

  if (idSport !== '0') {
    prems.push(fetchEvents(null, start, end, idSport));
  }

  const [meta, topOffer, events] = await Promise.all(prems);

  if (state === null) {
    state = nsoft.makeInitialState();
  }

  nsoft.buildState(state, {
    meta: meta.data.data,
    topOffer: topOffer.data.data,
    events: events ? events.data.data : null,
    shortProps: false,
  });

  return state;
};

export const fetchPrematch2 = async (start, end, idSport, skipMeta) => {
  let meta, topOffer, events;

  if (!skipMeta) {
    const prems = [fetchMeta(null, start, end, true), fetchTopOffer(null, start, end)];

    if (idSport !== '0') {
      prems.push(fetchEvents(null, start, end, idSport));
    }

    [meta, topOffer, events] = await Promise.all(prems);
  } else {
    if (idSport !== '0') {
      events = await fetchEvents(null, start, end, idSport);
    }
  }

  return {
    meta: meta ? meta.data.data : null,
    topOffer: topOffer ? topOffer.data.data : null,
    events: events ? events.data.data : null,
    shortProps: false,
  };
};
