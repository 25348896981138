import React, { useContext } from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    iconName: '',
  },
  visibility: {},
};

const IconAnchor = styled.i((props) => props.$styleText);

const BootstrapIcon = (componentProps) => {
  let props = componentProps;

  const dataElementContext = useContext(DataElementContext);
  let isVisible = true;

  [props, isVisible] = processComponentProps(props, dataElementContext);

  if (!isVisible) return null;

  const { styleText, className } = props;
  let iconName = props.properties.iconName;

  const extraDataProps = {};
  if (props?.properties) {
    Object.keys(props?.properties).forEach((key) => {
      if (key.indexOf('data-') === 0) {
        extraDataProps[key] = props?.properties[key];
      }
    });
  }

  return <IconAnchor className={`bi ${iconName} ${className ?? ''}`} $styleText={styleText} style={props.style} {...extraDataProps} />;
};

export default BootstrapIcon;
